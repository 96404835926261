<template>
	<div class="accountTable">
		<div class="accountTable_related">
			<div class="accountTable_related_title">
				已关联老师：<img @click="handleForm('editTeacher')" src="@/assets/images/dashboard/edit_black.png"
					class="related_teacher_icon">
			</div>
			<div class="accountTable_related_teacher">
				<div class="related_teacher_item">
					<span class="related_teacher_label">主管顾问：</span>
					<span class="related_teacher_name">{{teacherList.principalTeacher}}</span>
				</div>
				<div class="related_teacher_item">
					<span class="related_teacher_label">顾问老师：</span>
					<span class="related_teacher_name">{{teacherList.otherTeacher}}</span>
				</div>
			</div>
		</div>
		<!-- 添加其余申请账户 -->
		<div class="accountTable_handle_btn">
			<span @click="handleForm('accountAdd')" class="accountTable_add_btn">
				<img src="@/assets/images/dashboard/add.png">添加其余申请账户
			</span>
			<span v-if="showSaveBtn" class="accountTable_save_btn" @click="submitSave">保存</span>
		</div>
		<div class="accountTable_content">
			<div class="table_box" v-if="accountData.length > 0">
				<div class="table_td" v-for="(item,index) in accountData" :key="index">
					<div class="table_tr">
						<div class="table_label" style="padding: 0;">
							<!-- {{item.accountDesc}} -->
              <input :placeholder="`请输入`" class="table_value table_label_ipt" v-model="item.accountDesc"></input>
						</div>
						<input :placeholder="`请输入${item.account}`" class="table_value clear_border_right" v-model="item.account"></input>
					</div>
					<div class="table_tr">
						<div class="table_label">
							{{item.accountDesc}}密码
						</div>
						<input :placeholder="`请输入${item.account}密码`" class="table_value clear_border_right" v-model="item.password"></input>
					</div>
				</div>
			</div>
		    <div class="accountTable_empty" v-else>
		    	<img src="@/assets/images/common/empty1.png">
		    	<span>暂无账号</span>
		    </div>
		</div>
		<!-- 弹窗 -->
		<div class="accountTable_dialog">
			<!-- 关联老师编辑/添加申请账户 -->
			<el-dialog :modal="false" class="dialog_bg" :append-to-body="false" :modal-append-to-body="false" @close="closeDialog" width="579px" :title="diaLogTitle"
				:visible.sync="diaLogVisible">
				<div class="handle_form">
					<el-form v-if="diaLogTitle == '关联老师编辑'" ref="teacherDetail" :rules="teacherRules"
						:model="teacherDetail" label-width="90px">
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="flex_item" label="主要负责老师:" prop="principalTeacherId">
									<el-select v-model="teacherDetail.principalTeacherId" class="handle_form_select" clearable
										@change="$forceUpdate()" placeholder="请选择">
										<el-option v-for="item in mainTeachers" :key="item.id"
											:label="item.realname" :value="item.id">
										</el-option>
									</el-select>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="flex_item" label="顾问老师:" prop="otherTeacherId">
									<el-select v-model="teacherDetail.otherTeacherId" class="handle_form_select" multiple
										@change="$forceUpdate()" placeholder="请选择">
										<el-option v-for="item in counselorTeachers" :key="item.id"
											:label="item.realname" :value="item.id">
										</el-option>
									</el-select>
								</el-form-item>
							</div>
						</div>
					</el-form>
					<el-form v-if="diaLogTitle == '添加申请账户'" ref="accountDetail" :rules="accountRules"
						:model="accountDetail" label-width="90px">
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="flex_item" label="账户名称:" prop="accountDesc">
									<input class="handle_form_ipt" placeholder="请输入" v-model="accountDetail.accountDesc"/>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="flex_item" label="登录账号:" prop="account">
									<input class="handle_form_ipt" placeholder="请输入" v-model="accountDetail.account"/>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="flex_item" label="登录密码:" prop="password">
									<input class="handle_form_ipt" placeholder="请输入" v-model="accountDetail.password"/>
								</el-form-item>
							</div>
						</div>
					</el-form>
					<div class="handle_form_btn">
						<span @click="closeDialog" class="handle_form_cancel">取消</span>
						<span v-preventReClick @click="submitAccount" class="handle_form_confirm">确定</span>
					</div>
				</div>
			</el-dialog>
		</div>
	</div>
</template>

<script>
import {
  listTeacher,
  listStudentAccount,
  addStudentAccount,
  updateStudentAccount,
  updateTeacher,
  personalViewStudents
} from "@/api/common";

export default {
  props: {
    studentId: {
      type: String,
      default: function() {
        return "";
      }
    }
  },
  data() {
    return {
      teacherRules: {
        principalTeacherId: [
          {
            required: true,
            message: "请选择主要负责老师",
            trigger: "change"
          }
        ],
        otherTeacherId: [
          {
            required: true,
            message: "请选择顾问老师",
            trigger: "change"
          }
        ]
      },
      accountRules: {
        accountDesc: [
          {
            required: true,
            message: "请输入账号名称",
            trigger: "blur"
          }
        ],
        account: [
          {
            required: true,
            message: "请输入账号",
            trigger: "blur"
          }
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur"
          }
        ]
      },
      diaLogTitle: "",
      diaLogVisible: false,
      teacherDetail: {
        principalTeacherId: "",
        otherTeacherId: ""
      },
      accountDetail: {},
      accountData: [],
      mainTeachers: [],
      counselorTeachers: [],
      accountData: {},
      accountDataCopy: [],
      teacherList: {
        principalTeacher: "",
        otherTeacher: ""
      },
      showSaveBtn: false,
      disableSubmit: false
    };
  },
  watch: {
    accountData: {
      handler(val) {
        console.log("accountData-watch", val, this.accountDataCopy);
        let origin = JSON.stringify(val);
        let copy = JSON.stringify(this.accountDataCopy);
        if (origin != copy) {
          this.showSaveBtn = true;
        } else {
          this.showSaveBtn = false;
        }
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    this.texcherList();
    // this.studentPersonalView()
    // this.getStudentAccountData()
  },
  methods: {
    // 获取老师列表
    texcherList() {
      let params = {
        pageIndex: 1,
        pageSize: 99999
      };
      listTeacher(params).then(res => {
        this.mainTeachers = res.data;
        this.counselorTeachers = res.data;
        this.studentPersonalView();
      });
    },
    //根据学生id获取老师信息
    studentPersonalView() {
      if (!this.studentId) return;
      let params = {
        studentInfoId: this.studentId
      };
      personalViewStudents(params).then(res => {
        if (res.code == 0) {
          var principalTeacher = res.data.principalTeacherId;
          var otherTeacher = res.data.otherTeacherId.split(",");
          this.teacherDetail.principalTeacherId = principalTeacher;
          this.teacherDetail.otherTeacherId = otherTeacher;
          let teacherArr = [];
          this.mainTeachers.forEach(el => {
            if (el.id == principalTeacher) {
              this.teacherList.principalTeacher = el.displayName;
            }
            otherTeacher.forEach(item => {
              if (item == el.id) {
                teacherArr.push(el.displayName);
              }
            });
          });
          this.teacherList.otherTeacher = teacherArr.join("、");
        }
      });
    },
    getStudentAccountData() {
      listStudentAccount({ studentInfoId: this.studentId }).then(res => {
        if (res.code == 0) {
          this.accountData = res.data;
          this.showSaveBtn = false;
          this.accountDataCopy = JSON.parse(JSON.stringify(this.accountData));
        }
      });
    },
    submitSave() {
      updateStudentAccount(this.accountData).then(res => {
        if (res.code == 0) {
          this.getStudentAccountData();
        }
      });
    },
    closeDialog() {
      this.diaLogVisible = false;
    },
    handleForm(type) {
      switch (type) {
        case "editTeacher":
          this.diaLogTitle = "关联老师编辑";
          this.diaLogVisible = true;
          this.studentPersonalView();
          break;
        case "accountAdd":
          this.diaLogTitle = "添加申请账户";
          this.disableSubmit = false;
          this.diaLogVisible = true;
          if (this.$refs["accountDetail"] !== undefined) {
            this.$refs["accountDetail"].resetFields();
          }
          break;
      }
    },
    handleTeacher() {
      this.$refs["teacherDetail"].validate(valid => {
        if (valid) {
          let teacherForm = JSON.parse(JSON.stringify(this.teacherDetail));
          teacherForm.otherTeacherId = teacherForm.otherTeacherId.join(",");
          teacherForm.studentAccountId = this.studentId;
          updateTeacher(teacherForm).then(res => {
            if (res.code == 0) {
              this.diaLogVisible = false;

              this.studentPersonalView();
            }
          });
        }
      });
    },
    updateAccount() {
      this.accountDetail.studentAccountId = this.studentId;
      addStudentAccount(this.accountDetail).then(res => {
        if (res.code == 0) {
          this.diaLogVisible = false;
          this.getStudentAccountData();
        } else {
          this.disableSubmit = false;
        }
      });
    },

    submitAccount() {
      if (this.diaLogTitle == "关联老师编辑") {
        this.handleTeacher();
      } else {
        this.$refs["accountDetail"].validate(valid => {
          if (valid) {
            if (this.disableSubmit) return;
            this.disableSubmit = true;
            this.updateAccount();
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../styles/index.scss";
@import "./common.scss";

.accountTable_dialog input:focus {
  outline: none;
  border: 1px solid #eeeeee !important;
}

.table_label_ipt {
  border: 0 !important;
  background: #F7F7F7;
}


.flex_item {
  display: flex;
  align-items: center;
}

.accountTable {
  height: 100%;
  display: flex;
  flex-direction: column;

  .accountTable_related {
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    border-bottom: 1px solid #eeeeee;

    .accountTable_related_title {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      line-height: 24px;
      color: #333333;
      margin-bottom: 10px;
      display: flex;
      align-items: center;

      .related_teacher_icon {
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
    }

    .accountTable_related_teacher {
      display: flex;
      align-items: center;

      .related_teacher_item {
        display: flex;
        align-items: center;
        margin-right: 40px;

        .related_teacher_label {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 20px;
          color: #333333;
        }

        .related_teacher_name {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 20px;
          color: $theme_color;
          margin-right: 10px;
        }
      }
    }
  }

  .accountTable_handle_btn {
    display: flex;
    align-items: center;
    margin: 20px 0;

    .accountTable_add_btn {
      width: 171px;
      line-height: 32px;
      border: 1px solid $theme_color;
      border-radius: 2px;
      text-align: center;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: $theme_color;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      img {
        width: 11px;
        height: 11px;
        margin-right: 8px;
      }
    }

    .accountTable_save_btn {
      width: 76px;
      line-height: 32px;
      background: $theme_color;
      border: 1px solid $theme_color;
      border-radius: 4px;
      text-align: center;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      margin-left: 24px;
      cursor: pointer;
    }
  }

  .accountTable_content {
    flex: 1;

    .accountTable_empty {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 300px;
        height: 250px;
      }

      span {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 20px;
        color: #999999;
      }
    }
  }

  .accountTable_dialog {
    .handle_form {
      .handle_form_row {
        margin-top: 25px;
        // height: 48px;
        display: flex;
        // padding: 0 23px;

        .handle_form_item {
          flex: 1;
          display: flex;

          .handle_form_select {
            width: 400px;
          }

          .handle_form_ipt {
            width: 400px;
            border: 1px solid #eeeeee;
            outline: none;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 40px;
            color: #000000;
            padding: 0 10px;
            box-sizing: border-box;
          }
        }
      }

      .handle_form_btn {
        display: flex;
        align-items: center;
        margin: 40px 0 0 0;

        .handle_form_cancel {
          margin-left: auto;
          width: 76px;
          line-height: 34px;
          background: #ffffff;
          border: 1px solid $theme_color;
          border-radius: 4px;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: $theme_color;
          text-align: center;
          cursor: pointer;
          margin-right: 16px;
        }

        .handle_form_confirm {
          width: 76px;
          line-height: 34px;
          background: $theme_color;
          border-radius: 4px;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #fff;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
