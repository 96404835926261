<template>
	<div class="educationTable">
		<div class="flex_item">
			<div class="handle_form">
				<el-form class="flex_item" ref="searchForm" :model="searchForm" label-width="70px" size="mini">
					<div class="handle_form_row">
						<div class="handle_form_item">
							<el-form-item class="flex_item" label="">
								<el-select class="handle_form_select" clearable placeholder="请选择"
									v-model="searchForm.educationType">
									<el-option v-for="item in educationTypeList" :key="item.value" :label="item.label"
										:value="item.value">
									</el-option>
								</el-select>
							</el-form-item>
						</div>
					</div>
				</el-form>
			</div>
			<div class="handle_search flex_between" style="flex: 1;">
				<span @click="handleFn('add')" class="handle_search_confirm">添加</span>
				<span @click="handleFn('save')" v-if="showSaveBtn" class="educationTable_btn">保存</span>
			</div>
			
		</div>
		<div class="" v-if="educationViews.length > 0">
			<div class="educationTable_box" v-for="(item, index) in educationViews" :key="index">
				<div class="educationTable_title">
					<span class="educationTable_title_left">{{educationTypeText(item.educationType)}}</span>
					<span class="educationTable_title_right">
						<div class="flex-start mt">
							<div class="" @click="handleFn('delete',item,index)">
								<img class="delete-icon" src="@/assets/images/common/delete-icon.png" alt="">
								<span class="delete-text">删除任务</span>
							</div>
						</div>
					</span>
				</div>
				<div class="educationTable_content">
					<div class="table_box">
						<div class="table_td">
							<div class="table_tr">
								<div class="table_label">
									学校名称
								</div>
								<input v-model="item.schoolName" placeholder="请输入学校名称"
									class="table_value clear_border_right"></input>
							</div>
							<div class="table_tr">
								<div class="table_label">
									毕业时间
								</div>
								<div class="table_value">
									<span class="personalTable_date"><i
											class="personalTable_date_icon el-icon-date"></i>{{item.graduateDate?item.graduateDate:'请选择'}}</span>
									<el-date-picker @change="dateSelect($event, 'graduateDate', item)"
										v-model="item.graduateDate" type="date" placeholder="选择日期">
									</el-date-picker>
								</div>
							</div>
						</div>
						<div v-if="item.educationType == 2" class="table_td">
							<div class="table_tr">
								<div class="table_label">
									年级总人数
								</div>
								<input v-model="item.gradeNumber" type="number" ref="inputValue" placeholder="请输入年级总人数"
									class="table_value" @blur="handleBlur"></input>
							</div>
							<div class="table_tr">
								<div class="table_label">
									高三学年所学课程
								</div>
								<input v-model="item.gradeNineCourse" placeholder="请输入高三学年所学课程"
									class="table_value"></input>
							</div>
						</div>
						<div class="table_td">
							<div class="table_tr">
								<div class="table_label border_bottom">
									学校地址
								</div>
								<input v-model="item.schoolAddr" placeholder="请输入学校地址"
									class="table_value border_bottom"></input>
							</div>
						</div>
					</div>
					<!-- <div @click="handleFn('delete',item,index)">删除</div> -->
				</div>
			</div>
		</div>
		<div class="educationTable_empty" v-else>
			<img src="@/assets/images/common/empty1.png">
			<span>暂无教育信息</span>
		</div>
		<messege-box ref="messegeBox" @confirmMessege="confirmMessege" @closeMessege="closeMessege"
			:isOpen="messegeVisible" :messegeTip="messegeTip" :messegeContent="messegeContent"
			:messegeType="messegeType" />
	</div>
</template>

<script>
	import {
		educationViewStudents,
		AddStudentEducation,
		DeleteStudentEducation
	} from "@/api/common"
	import commonFn from "@/utils/common.js";
	export default {
		data() {
			return {
				educationViews: [],
				searchForm: {
					educationType: '',
					studentAccountId: this.studentId
				},
				educationTypeList: [{
						label: "大学",
						value: "1"
					},
					{
						label: "高中",
						value: "2"
					},
					{
						label: "初中",
						value: "3"
					},
					{
						label: "小学",
						value: "4"
					},
					{
						label: "其他",
						value: "5"
					},
				],
				messegeVisible: false,
				messegeTip: "",
				messegeContent: "",
				messegeType: "",
				deleteObj:{},
				index:-1,
				educationViewsCopy: [],
				showSaveBtn: false
			}
		},
		props: {
			studentId: {
				type: String,
				default: function() {
					return ""
				}
			}
		},
		watch: {
			educationViews: {
				handler(val) {
					console.log("educationViews-watch", val, this.educationViewsCopy)
					let origin = JSON.stringify(val)
					let copy = JSON.stringify(this.educationViewsCopy)
					if(origin != copy) {
						this.showSaveBtn = true
					} else {
						this.showSaveBtn = false
					}
				},
				immediate: true,
				deep: true
			}
		},
		methods: {
			closeMessege() {
				this.messegeVisible = false
			},
			confirmMessege() {
				if(!this.deleteObj.studentEducationTypeId){
					this.educationViews.splice(this.index,1)
					this.messegeVisible = false
					this.educationViewsCopy = JSON.parse(JSON.stringify(this.educationViews))
				}else{
					this.messegeVisible = false
					this.differentTabFn(this.deleteObj)
				}
			},
			differentTabFn(deleteObj){
				DeleteStudentEducation(deleteObj).then(res=>{
					if(res.code ==0 ){
						this.messegeVisible = false
						this.$message({
							message: "学生教育资料删除成功~",
							type: 'success'
						})
						this.showSaveBtn = false
						this.studenEducationView()
					}
				})
			},
			// 归类数组
			formatArr() {
				let emptyArr = []
				let newArr = []
				let mapObj = {
					'1': '1',
					'2': '2',
					'3': '3',
					'4': '4',
					'5': '5',
				}
				resData.forEach(item => {
					if (emptyArr.indexOf(item.educationType) === -1) {
						newArr.push({
							key: mapObj[item.educationType],
							value: [item]
						})
						emptyArr.push(item.educationType)
					} else {
						newArr[emptyArr.indexOf(item.educationType)].value.push(item.educationType)
					}
				})
				console.log('newArr>>>>', newArr)
			},
			// 数组排序
			sortArr(property) {
				return function(a, b) {
					var value1 = a[property];
					var value2 = b[property];
					return value1 - value2
				}
			},
			// 时间选择
			dateSelect(value, key, item) {
				console.log("dateSelect", value, item)
				item[key] = commonFn.timeFormat(value, "yyyy-MM-dd")
				this.$forceUpdate()
			},
			// 教育类型映射
			educationTypeText(val) {
				let mapObj = {
					"1": "大学 ",
					"2": "高中",
					"3": "初中",
					"4": "小学",
					"5": "其他",
				}
				return mapObj[val]
			},
			// 获取学生教育信息
			studenEducationView() {
				let params = {
					studentInfoId: this.studentId
				}
				this.educationViews = []
				educationViewStudents(params).then((res) => {
					if (res.code == 0) {
						if (res.data.length > 0) {
							let resData = res.data
							resData.sort(this.sortArr("educationType"))
							console.log("resData", resData)
							this.educationViews = resData
							this.educationViewsCopy = JSON.parse(JSON.stringify(this.educationViews))
						}
					}
				})
			},
			//高中年纪总人数失去焦点事件
			handleBlur() {
				var value = this.$refs.inputValue.value
				if (value) {

				}
			},
			handleFn(type,item,index) {
				switch (type) {
					case "add":
						if (!this.searchForm.educationType) {
							this.$message({
								message: "请选择教育类型~",
								type: 'error'
							})
							return
						}
						let educationObj = {
							...this.searchForm,
							schoolName: "",
							graduateDate: "",
							schoolAddr: ""
						}
						if (this.searchForm.educationType == 2) {
							this.$set(educationObj, "gradeNumber", "")
							this.$set(educationObj, "gradeNineCourse", "")
						}
						this.educationViews.push(educationObj)
						this.educationViews.sort(this.sortArr("educationType"))
						this.educationViewsCopy = JSON.parse(JSON.stringify(this.educationViews))
						break;
					case "save":
						// 保存教育信息
						AddStudentEducation(this.educationViews).then((res) => {
							if (res.code == 0) {
								this.$message({
									message: "教育信息编辑成功~",
									type: 'success'
								})
								this.showSaveBtn = false
								this.studenEducationView()
							}
						})
						break;
				case "delete":
					// if(!item.studentEducationTypeId){
					// 	this.educationViews.splice(index,1)
					// }else{
						if(item.studentEducationTypeId){
							this.deleteObj = {
								studentEducationTypeId: item.studentEducationTypeId
							}
						}else{
							this.deleteObj = {}
						}
						if(index){
							this.index = index
						}
						this.messegeVisible = true
						this.messegeTip = "信息提示"
						this.messegeContent = "确定删除当前选中教育信息吗？"
						this.messegeType = "warn"
					// }
					break;
				}
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import "../../../styles/index.scss";
	@import "./common.scss";

	.height_87 {
		height: 87px !important;
	}

	/deep/.handle_form_select .el-input__inner {
		height: 36px;
	}
	
	.flex_between {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	
	.educationTable_btn {
		width: 80px;
		background: $theme_color;
		border-radius: 4px;
		margin-left: auto;
		font-size: 14px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		line-height: 34px;
		color: #FFFFFF;
		text-align: center;
		cursor: pointer;
		margin-left: 10px;
	}


	.educationTable {
		height: 100%;
		display: flex;
		flex-direction: column;

		.educationTable_box {
			display: flex;
			flex-direction: column;

			.educationTable_title {
				display: flex;
				line-height: 24px;
				margin-top: 20px;
				

				.educationTable_title_left {
					font-size: 16px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #333333;
					box-sizing: border-box;
					border-bottom: 2px solid $theme_color;
				}

				.educationTable_title_right {
					flex: 1;
					border-bottom: 1px solid #EEEEEE;
					display: flex;
					.mt{
						margin-top:24px;
						cursor: pointer;
						margin-left:auto;
						.delete-icon{
							width:14px;
							height:14px;
							margin-right:8px;
						}
						.delete-text{
							font-size:14px;
						}
					}
					
				}
			}

			.educationTable_content {
				flex: 1;
				margin-top: 10px;
			}
		}

		.educationTable_empty {
			flex: 1;
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			img {
				width: 300px;
				height: 250px;
			}

			span {
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				line-height: 20px;
				color: #999999;
			}
		}
	}

	/* 普通IE浏览器 样式清除 */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none !important;
	}

	/* 火狐浏览器样式清除 */
	input[type="number"] {
		-moz-appearance: textfield;
	}
</style>
