<template>
	<div class="tab_box">
		<div @click="changeViews(item)" v-for="(item,index) in tabList" :key="index"
			:class="{'tab_active': currentTab == item.value}" class="tab_item">
			{{item.label}}
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			tabList: {
				type:Array,
				default: function() {
					return []
				}
			},
			currentTab: {
				type: String,
				default: function() {
					return ""
				}
			}
		},
		data () {
			return {
				
			}
		},
		methods: {
			changeViews(item) {
				this.$emit("changeViews", item)
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import "../../styles/index.scss";
	.tab_box {
		display: flex;
		align-items: center;
		border: 1px solid #EEEEEE;
		border-radius: 4px;

		.tab_item {
			line-height: 36px;
			text-align: center;
			font-size: 14px;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: #666666;
			cursor: pointer;
			padding: 0 40px;
			user-select: none;

			&.tab_active {
				background-color: $theme_color;
				color: #FFFFFF;
			}
		}
	}
	.tab_box :not(:first-child) {
		border-left: 1px solid #EEEEEE;
	}
</style>
