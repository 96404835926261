<template>
	<div class="awardsTable">
		<!-- 添加奖项 -->
		<div class="awardsTable_handle_btn">
			<span @click="handleForm('table')" class="awardsTable_add_btn">
				<img src="@/assets/images/dashboard/add.png">添加奖项
			</span>
		</div>
		<!-- 奖项列表 -->
		<div class="awardsTable_table">
			<div class="awardsTable_table_box">
				<el-table class="awardsTable_table_inner" :header-cell-style="headStyle" :cell-style="rowStyle"
					:data="tableData" border>
					<el-table-column prop="awardName" label="奖项名称">
						<template slot-scope="scope">
							<input placeholder="请输入奖项名称" class="awardsTable_table_ipt" type=""
								v-model="scope.row.awardName" />
						</template>
					</el-table-column>
					<el-table-column prop="awardLevel" label="奖项等级">
						<template slot-scope="scope">
							<input placeholder="请输入奖项等级" class="awardsTable_table_ipt" type=""
								v-model="scope.row.awardLevel" />
						</template>
					</el-table-column>
					<el-table-column prop="awardDesc" label="奖项描述">
						<template slot-scope="scope">
							<textarea placeholder="请输入奖项描述" class="awardsTable_table_area" type=""
								v-model="scope.row.awardDesc" />
						</template>
					</el-table-column>
					<el-table-column prop="date" label="获奖时间">
						<template slot-scope="scope">
							<div class="awardsTable_table_date">
								<span class="table_date_inner"><i
										class="table_date_icon el-icon-date"></i>{{scope.row.awardDate?scope.row.awardDate:'请选择'}}</span>
								<el-date-picker @change="dateSelect($event, 'awardDate', scope.row)"
									v-model="scope.row.awardDate" type="date" placeholder="选择日期">
								</el-date-picker>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="action" label="附件">
						<template slot-scope="scope">
							<div class="awardsTable_table_action">
								<span v-if="!scope.row.attPath" @click="handleForm('upload', scope)">上传</span>
								<span v-if="scope.row.attPath" @click="handleForm('download', scope.row)">下载</span>
								<span style="margin-left: 10px;" v-if="scope.row.attPath"
									@click="handleForm('upload', scope)">重传</span>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="action" label="操作">
						<template slot-scope="scope">
							<div class="awardsTable_table_action">
								<span @click="handleForm('save', scope.row)">保存</span>
								<span style="margin-left: 10px;" @click="handleForm('delete', scope)">删除</span>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<messege-box ref="messegeBox" @confirmMessege="confirmMessege" @closeMessege="closeMessege"
			:isOpen="messegeVisible" :messegeTip="messegeTip" :messegeContent="messegeContent"
			:messegeType="messegeType" />
		<el-upload class="uploadImage" style="display:none;" :action="upLoadUrl" :show-file-list="false"
			:before-upload="beforeUpload"></el-upload>
	</div>
</template>

<script>
	import {
		awardStudents,
		AddStudentAward,
		EditStudentAward,
		DeleteStudentAward,
	} from "@/api/common"
	import commonFn from "@/utils/common.js";
	import {
		getImgSrc,
		upLoadFile
	} from "@/api/OSS";
	export default {
		data() {
			return {
				messegeVisible: false,
				messegeTip: "",
				messegeContent: "",
				messegeType: "",
				tableData: [],
				deleteObj: {},
				upLoadUrl: "",
				upLoadIndex: undefined,
			}
		},
		props: {
			studentId: {
				type: String,
				default: function() {
					return ""
				}
			}
		},
		methods: {
			headStyle() {
				return 'text-align:center;background: #F7F7F7;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;'
			},
			rowStyle() {
				return 'text-align:center;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #333333;'
			},
			closeMessege() {
				this.messegeVisible = false
			},
			confirmMessege() {
				this.differentTabFn('tabelDelete', this.deleteObj)
			},
			// 附件上传
			beforeUpload(file) {
				let fileName = file.name;
				let fileType = file.type;
				let pointIndex = fileName.lastIndexOf('.')
				let type = fileName.substring(pointIndex + 1, fileName.length).toLowerCase()
				console.log("fileType", type)
				if(type == 'txt'||type == 'zip') {
					this.$message({
						message: "暂不支持txt、zip文件格式~",
						type: "error"
					});
					return false;
				}
				getImgSrc({
					fileName: fileName
				}).then((res) => {
					if (res.code != 0) return;
					this.upLoadUrl = res.data.uploadUrl;
					console.log("获取文件上传地址>>>>>", res.data.uploadUrl);
					upLoadFile(file, this.upLoadUrl, fileType).then((res) => {
						const fileUrl = res.config.url.substring(
							0,
							res.config.url.indexOf("?")
						);
						this.tableData[this.upLoadIndex].attPath = fileUrl
					});
				});
			},
			// 时间选择
			dateSelect(value, key, item) {
				console.log("dateSelect", value, item)
				item[key] = commonFn.timeFormat(value, "yyyy-MM-dd")
				this.$forceUpdate()
			},
			// 获取学生奖项信息
			studentsAwardView() {
				let params = {
					studentInfoId: this.studentId
				}
				this.tableData = []
				this.studentsAwardHandle("list", params)
			},
			// 学生奖项信息操作
			studentsAwardHandle(type, params) {
				if (type == 'list') {
					// 学生奖项信息列表
					awardStudents(params).then(res => {
						if (res.code == 0) {
							this.tableData = res.data
						}
					})
				} else if (type == 'update') {
					if (params.studentAwardId) {
						// 学生奖项信息编辑
						EditStudentAward(params).then((res) => {
							if (res.code == 0) {
								this.$message({
									message: "学生奖项信息编辑成功~",
									type: 'success'
								})
								this.studentsAwardView()
							}
						})
					} else {
						// 学生奖项信息新增
						AddStudentAward(params).then((res) => {
							if (res.code == 0) {
								this.$message({
									message: "学生奖项信息添加成功~",
									type: 'success'
								})
								this.studentsAwardView()
							}
						})
					}
				} else if (type == 'delete') {
					// 学生奖项信息删除
					DeleteStudentAward(params).then((res) => {
						if (res.code == 0) {
							this.$message({
								message: "学生奖项信息删除成功~",
								type: 'success'
							})
							this.messegeVisible = false
							this.studentsAwardView()
						}
					})
				}
			},
			differentTabFn(type, params) {
				if (type == 'tableList') {
					this.studentsAwardHandle('list', params)
				} else if (type == 'tableSave') {
					this.studentsAwardHandle('update', params)
				} else if (type == 'tabelDelete') {
					this.studentsAwardHandle("delete", params)
				} else {
					let formObj = {
						studentAccountId: this.studentId,
						awardName: "",
						awardLevel: "",
						awardDesc: "",
						awardDate: "",
						attPath: "",
					}
					this.tableData.push(formObj)
				}
			},
			handleForm(type, scope) {
				switch (type) {
					case "table":
						this.differentTabFn('tableAdd')
						break;
					case "delete":
						if (!scope.row.studentAwardId) {
							this.tableData.splice(scope.$index, 1)
						} else {
							this.deleteObj = {
								studentAwardId: scope.row.studentAwardId
							}
							this.messegeVisible = true
							this.messegeTip = "信息提示"
							this.messegeContent = "确定删除当前选中奖项信息吗？"
							this.messegeType = "warn"
						}
						break;
					case "upload":
						this.upLoadIndex = scope.$index
						console.log("handleForm", this.upLoadIndex)
						document.querySelector(".uploadImage input").click();
						break;
					case "download":
						let pointIndex = scope.attPath.lastIndexOf('.')
						let lineIndex = scope.attPath.lastIndexOf('/')
						let fileName = ""
						let fileType = ""
						if (pointIndex != -1) {
							fileName = scope.attPath.substring(lineIndex + 1, pointIndex).toLowerCase()
							fileName = this.getCharFromUtf8(fileName)
							fileType = scope.attPath.substring(pointIndex + 1, scope.attPath.length).toLowerCase()
							// console.log("fileType", fileType)
						}
						if (fileType == 'txt') {
							let xhr = new XMLHttpRequest();
							xhr.open("get", scope.attPath, true);
							xhr.responseType = "blob";
							xhr.onload = function() {
								if (this.status == 200) {
									const reader = new FileReader()
									reader.onload = function() {
										// console.log('reader.result', reader.result)
										var blob = new Blob([reader.result], {
											type: 'text/plain;charset=utf-8'
										})
										saveAs(blob, fileName + '.txt')
									}
									reader.readAsText(this.response);
								}
							};
							xhr.send();
						} else {
							window.open(scope.attPath)
						}
						break;
					case "save":
						this.differentTabFn('tableSave', scope)
						break;
				}
			},
			getCharFromUtf8(str) {
				var cstr = "";
				var nOffset = 0;
				if (str == "")
					return "";
				str = str.toLowerCase();
				nOffset = str.indexOf("%e");
				if (nOffset == -1)
					return str;
				while (nOffset != -1) {
					cstr += str.substr(0, nOffset);
					str = str.substr(nOffset, str.length - nOffset);
					if (str == "" || str.length < 9)
						return cstr;
					cstr += this.utf8ToChar(str.substr(0, 9));
					str = str.substr(9, str.length - 9);
					nOffset = str.indexOf("%e");
				}
				return cstr + str;
			},
			utf8ToChar(str) {
				var iCode, iCode1, iCode2;
				iCode = parseInt("0x" + str.substr(1, 2));
				iCode1 = parseInt("0x" + str.substr(4, 2));
				iCode2 = parseInt("0x" + str.substr(7, 2));
				return String.fromCharCode(((iCode & 0x0F) << 12) | ((iCode1 & 0x3F) << 6) | (iCode2 & 0x3F));
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import "../../../styles/index.scss";

	input::-webkit-input-placeholder {
		font-size: 14px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		line-height: 20px;
		color: #999999 !important;
	}

	textarea::-webkit-input-placeholder {
		font-size: 14px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		line-height: 20px;
		color: #999999 !important;
	}

	/deep/.awardsTable_table input:focus {
		outline: none;
		border: 1px solid $theme_color !important;
	}

	/deep/.awardsTable_table textarea:focus {
		outline: none;
		border: 1px solid $theme_color !important;
	}

	/deep/.awardsTable_table .el-table tbody tr:hover>td {
		background-color: rgba(91, 168, 151, 0) !important
	}

	/deep/.awardsTable_table_date .el-input__inner {
		height: 120px;
	}

	.flex_item {
		display: flex;
		align-items: center;
	}

	.awardsTable {
		width: 100%;
		display: flex;
		flex-direction: column;
		position: absolute;

		.awardsTable_handle_btn {
			display: flex;
			align-items: center;
			margin: 20px 0;

			.awardsTable_add_btn {
				width: 107px;
				line-height: 32px;
				border: 1px solid $theme_color;
				border-radius: 2px;
				text-align: center;
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: $theme_color;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;

				img {
					width: 11px;
					height: 11px;
					margin-right: 8px;
				}
			}

			.awardsTable_save_btn {
				width: 76px;
				line-height: 32px;
				background: $theme_color;
				border: 1px solid $theme_color;
				border-radius: 4px;
				text-align: center;
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #FFFFFF;
				margin-left: 24px;
				cursor: pointer;
			}
		}

		.awardsTable_table {
			flex: 1;
			display: flex;
			flex-direction: column;

			.awardsTable_table_box {
				flex: 1;
				// position: relative;

				.awardsTable_table_inner {
					// position: absolute;
					width: 100%;

					.awardsTable_table_date {
						width: 100%;
						height: 120px;
						position: relative;

						.table_date_inner {
							height: 120px;
							flex: 1;
							display: flex;
							align-items: center;
							justify-content: center;

							.table_date_icon {
								margin-right: 10px;
							}
						}
					}

					.awardsTable_table_date .el-date-editor {
						width: 100%;
						height: 120px;
						position: absolute; //绝对定位
						top: 0;
						left: 0px;
						opacity: 0; //设置完全透明
					}

					.awardsTable_table_area {
						width: 100%;
						outline: none;
						box-sizing: border-box;
						height: 120px;
						border: 0;
						// text-align: center;
						padding: 5px 10px;
						resize: none;
					}

					.awardsTable_table_ipt {
						width: 100%;
						outline: none;
						box-sizing: border-box;
						height: 120px;
						border: 0;
						text-align: center;
						padding: 0 10px;
					}

					.awardsTable_table_select {
						// width: 100%;
						display: flex;
						align-items: center;
						justify-content: center;
						position: relative;

						.table_select_inner {
							display: flex;
							align-items: center;
							justify-content: center;
							height: 120px;

							span {
								font-size: 14px;
								font-family: Source Han Sans CN;
								font-weight: 400;
								line-height: 20px;
								color: #333333;
								margin-right: 8px;
							}

							img {
								width: 16px;
								height: 16px;
							}
						}
					}

					.awardsTable_table_action {
						width: 100%;
						height: 120px;
						display: flex;
						align-items: center;
						justify-content: center;

						span {
							font-size: 14px;
							font-family: Source Han Sans CN;
							font-weight: 400;
							line-height: 0px;
							color: $theme_color;
							cursor: pointer;
						}
					}

					.awardsTable_table_select .el-select {
						position: absolute; //绝对定位
						top: 0;
						left: 0px;
						opacity: 0; //设置完全透明
					}
				}
			}
		}
	}
</style>
