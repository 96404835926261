<template>
	<div class="personalTable">
		<div class="personalTable_title">
			<span class="personalTable_title_left">个人资料</span>
			<span v-if="showSaveBtn" class="personalTable_title_right">
				<span @click="handleFn('save')" class="personalTable_btn">保存</span>
			</span>
		</div>
		<div class="personalTable_content">
			<div class="table_box">
				<div class="table_td">
					<div class="table_tr">
						<div class="table_label">
							中文名
						</div>
						<input v-model="personalViews.name" placeholder="请输入中文名"
							class="table_value clear_border_right"></input>
					</div>
					<div class="table_tr">
						<div class="table_label">
							英文名
						</div>
						<input v-model="personalViews.enName" placeholder="请输入英文名" class="table_value"></input>
					</div>
				</div>
				<div class="table_td">
					<div class="table_tr">
						<div class="table_label">
							性别
						</div>
						<div class="table_value clear_border_right">
							<radioGroup :currentRadio="personalViews.gender+''" @checkRadio="sexRadio"
								:radioList="sexList" />
						</div>
					</div>
					<div class="table_tr">
						<div class="table_label">
							出生日期
						</div>
						<div class="table_value">
							<span class="personalTable_date"><i
									class="personalTable_date_icon el-icon-date"></i>{{personalViews.birthday?personalViews.birthday: '请选择'}}</span>
							<el-date-picker @change="birthdaySelect" v-model="personalViews.birthday" type="date" placeholder="选择日期">
							</el-date-picker>
						</div>
					</div>
				</div>
				<div class="table_td">
					<div class="table_tr">
						<div class="table_label">
							出生国家
						</div>
						<input v-model="personalViews.birthCountry" placeholder="请输入出生国家"
							class="table_value clear_border_right"></input>
					</div>
					<div class="table_tr">
						<div class="table_label">
							出生城市
						</div>
						<input v-model="personalViews.birthCity" placeholder="请输入出生城市" class="table_value"></input>
					</div>
				</div>
				<div class="table_td">
					<div class="table_tr">
						<div class="table_label">
							出生省份
						</div>
						<input v-model="personalViews.birthProvince" placeholder="请输入出生省份"
							class="table_value clear_border_right"></input>
					</div>
					<div class="table_tr">
						<div class="table_label">
							国籍
						</div>
						<input v-model="personalViews.nationality" placeholder="请输入国籍" class="table_value"></input>
					</div>
				</div>
				<div class="table_td">
					<div class="table_tr">
						<div class="table_label">
							邮箱
						</div>
						<input v-model="personalViews.email" placeholder="请输入邮箱"
							class="table_value clear_border_right"></input>
					</div>
					<div class="table_tr">
						<div class="table_label">
							联系电话
						</div>
						<input v-model="personalViews.tel" placeholder="请输入联系电话" class="table_value"></input>
					</div>
				</div>
				<div class="table_td">
					<div class="table_tr">
						<div class="table_label">
							申请状态
						</div>
						<div class="table_value clear_border_right">
							<div class="personalTable_select">
								<span class="tag_green">{{appStatusText(personalViews.appStatus)}}</span>
								<img src="@/assets/images/dashboard/moreArrow.png">
							</div>
							<el-select v-model="personalViews.appStatus" placeholder="请选择">
								<el-option v-for="item in appStatusList" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</div>
					</div>
					<div class="table_tr">
						<div class="table_label">
							家庭住址
						</div>
						<input v-model="personalViews.homeAddr" placeholder="请输入家庭住址" class="table_value"></input>
					</div>
				</div>
				<div class="table_td">
					<div class="table_tr">
						<div class="table_label">
							申请类型
						</div>
						<div class="table_value clear_border_right">
							<div class="personalTable_select">
								<span class="tag_green">{{appTypeText(personalViews.appType)}}</span>
								<img src="@/assets/images/dashboard/moreArrow.png">
							</div>
							<el-select v-model="personalViews.appType" placeholder="请选择">
								<el-option v-for="item in appTypeList" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</div>
					</div>
					<div class="table_tr">
						<div class="table_label">
							申请年份
						</div>
						<div class="table_value">
							<span class="personalTable_date"><i
									class="personalTable_date_icon el-icon-date"></i>{{personalViews.appDate?personalViews.appDate: '请选择'}}</span>
							<el-date-picker v-model="personalViews.appDate" @change="applydateSelect" type="date" placeholder="选择日期">
							</el-date-picker>
						</div>
					</div>
				</div>
				<div class="table_td">
					<div class="table_tr">
						<div class="table_label border_bottom">
							永久居留权
						</div>
						<input v-model="personalViews.permanentResidency" placeholder="请输入"
							class="table_value border_bottom clear_border_right"></input>
					</div>
					<div class="table_tr">
						<div class="table_label border_bottom">
							是否有美国签证（如有请添加空格填写签证号）
						</div>
						<input v-model="personalViews.usVisa" placeholder="请输入"
							class="table_value border_bottom"></input>
					</div>
				</div>
			</div>
		</div>
		<!-- 添加学生 -->
		<div class="personalTable_dialog">
			<el-dialog :modal="false" class="dialog_bg" :append-to-body="false" :modal-append-to-body="false" @close="closeStudentDialog" width="579px" :title="diaLogStudentTitle"
				:visible.sync="diaLogStudentVisible">
				<div class="handle_form">
					<el-form ref="studentForm" :rules="studentRules" :model="studentForm" label-width="100px">
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item prop="name" class="flex_item" label="学生姓名:">
									<input v-model="studentForm.name" placeholder="请输入学生姓名"
										class="handle_form_ipt"></input>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item prop="accountNumber" class="flex_item" label="学生手机号:">
									<input v-model="studentForm.accountNumber" placeholder="请输入学生手机号"
										class="handle_form_ipt"></input>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item prop="principalTeacherId" class="flex_item" label="主管顾问:">
									<el-select v-model="studentForm.principalTeacherId" class="handle_form_select"
										clearable placeholder="请选择">
										<el-option v-for="item in mainTeachers" :key="item.id"
											:label="item.realname" :value="item.id">
										</el-option>
									</el-select>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item prop="otherTeacherId" class="flex_item" label="顾问老师:">
									<el-select v-model="studentForm.otherTeacherId" class="handle_form_select" multiple
										placeholder="请选择">
										<el-option v-for="item in counselorTeachers" :key="item.id"
											:label="item.realname" :value="item.id">
										</el-option>
									</el-select>
								</el-form-item>
								<div style="width: 100px;" class="handle_form_tip">(可多个)</div>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item prop="appStatus" class="flex_item" label="申请状态:">
									<el-select v-model="studentForm.appStatus" class="handle_form_select" clearable
										placeholder="请选择">
										<el-option v-for="item in appStatusList" :key="item.value" :label="item.label"
											:value="item.value">
										</el-option>
									</el-select>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item prop="appType" class="flex_item" label="申请类型:">
									<el-select v-model="studentForm.appType" class="handle_form_select" clearable
										placeholder="请选择">
										<el-option v-for="item in appTypeList" :key="item.value" :label="item.label"
											:value="item.value">
										</el-option>
									</el-select>
								</el-form-item>
							</div>
						</div>
					</el-form>
					<div class="handle_form_btn">
						<span @click="closeStudentDialog" class="handle_form_cancel">取消</span>
						<span v-preventReClick class="handle_form_confirm" @click="handleFn('submit')">确定</span>
					</div>
				</div>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import {
		listTeacher,
		addStudents,
		editStudents,
		personalViewStudents,
	} from "@/api/common"
	import radioGroup from "@/components/radioGroup/index.vue";
	import commonFn from "@/utils/common.js";
	export default {
		components: {
			radioGroup
		},
		props: {
			studentId: {
				type: String,
				default: function() {
					return ""
				}
			}
		},
		data() {
			return {
				personalViews: {},
				personalCopy: {},
				sexList: [{
						label: "1",
						value: "男",
						check: true
					},
					{
						label: "2",
						value: "女",
						check: false
					}
				],
				appStatusList: [{
						label: "申请季",
						value: "1"
					},
					{
						label: "规划期",
						value: "2"
					},
					{
						label: "已完成",
						value: "3"
					}
				],
				appTypeList: [{
						label: "高中",
						value: "1"
					},
					{
						label: "本科",
						value: "2"
					},
					{
						label: "转学",
						value: "3"
					},
					{
						label: "研究生",
						value: "4"
					},
				],
				mainTeachers: [],
				counselorTeachers: [],
				diaLogStudentTitle: "",
				diaLogStudentVisible: false,
				studentRules: {
					name: [{
						required: true,
						message: '请输入学生姓名',
						trigger: 'blur'
					}],
					accountNumber: [{
						required: true,
						message: '请输入学生手机号',
						trigger: 'blur'
					}],
					principalTeacherId: [{
						required: true,
						message: '请选择主要负责老师',
						trigger: 'blur'
					}],
					otherTeacherId: [{
						required: true,
						message: '请选择顾问老师',
						trigger: 'blur'
					}],
					appStatus: [{
						required: true,
						message: '请选择申请状态',
						trigger: 'blur'
					}],
					appType: [{
						required: true,
						message: '请选择申请类型',
						trigger: 'blur'
					}],
				},
				studentForm: {
					name: "",
					studentAccountId: "",
					accountNumber: "",
					principalTeacherId: "",
					otherTeacherId: "",
					appStatus: "",
					appType: "",
				},
				showSaveBtn: false,
			};
		},
		computed: {},
		watch: {
			personalViews: {
				handler(val) {
					let keys = Object.keys(this.personalCopy)
					let isCommon = keys.some(item => val[item] != this.personalCopy[item])
					console.log("isCommon", isCommon)
					this.showSaveBtn = isCommon
				},
				immediate: true,
				deep: true
			},
			diaLogStudentVisible: {
				handler(val) {
					if(!val) {
						this.studentForm = {}
						this.$nextTick(() => {
							this.$refs.studentForm.clearValidate()
						})
					}
				},
				immediate: true,
				deep: true
			}
		},
		methods: {
			// 出生日期选择
			birthdaySelect(value) {
				console.log("birthdaySelect", value)
				this.personalViews.birthday = commonFn.timeFormat(value, "yyyy-MM-dd")
			},
			// 申请日期选择
			applydateSelect(value) {
				console.log("applydateSelect", value)
				this.personalViews.appDate = commonFn.timeFormat(value, "yyyy-MM-dd")
			},
			// 申请类型文本
			appStatusText(val) {
				let mapObj = {
					"1": "申请季",
					"2": "规划期",
					"3": "已完成"
				}
				return mapObj[val]
			},
			// 申请状态文本
			appTypeText(val) {
				let mapObj = {
					"1": "高中",
					"2": "本科",
					"3": "转学",
					"4": "研究生"
				}
				return mapObj[val]
			},
			// 获取老师列表
			texcherList() {
				let params = {
					pageIndex: 1,
					pageSize: 99999
				}
				listTeacher(params).then((res) => {
					this.mainTeachers = res.data
					this.counselorTeachers = res.data
				})
			},
			// 学生个人资料
			studentPersonalView() {
				let params = {
					studentInfoId: this.studentId
				}
				personalViewStudents(params).then((res) => {
					if (res.code == 0) {
						let viewKeys = ["enName", "birthday", "birthCountry", "birthCity",
							"birthProvince", "nationality", "email", "appDate", "permanentResidency", "usVisa", "homeAddr"
						]
						let resultData = res.data
						this.personalViews = resultData
						viewKeys.forEach(item => {
							if (resultData[item]) {
								this.$set(this.personalViews, item, resultData[item])
							} else {
								this.$set(this.personalViews, item, "")
							}
						})
						this.personalCopy = JSON.parse(JSON.stringify(this.personalViews))
						console.log("personalViews1", this.personalViews)
					}
				})
			},
			sexRadio(props) {
				console.log("sexRadio", props)
				this.personalViews.gender = props.label
			},
			handleFn(type) {
				switch (type) {
					case "save":
					let viewData = JSON.parse(JSON.stringify(this.personalViews))
					delete viewData.accountNumber
					editStudents(viewData).then((res) => {
						if(res.code == 0) {
							this.$message({
								message: "个人资料编辑成功~",
								type: 'success'
							})
							this.showSaveBtn = false
							this.$emit("studentAdd")
							this.studentPersonalView(this.studentId)
						}
					})
						break;
					case "submit":
						this.$refs["studentForm"].validate(valid => {
							if (valid) {
								let studentParams = JSON.parse(JSON.stringify(this.studentForm))
								studentParams.otherTeacherId = studentParams.otherTeacherId.join(",")
								console.log("studentParams", studentParams)
								addStudents(studentParams).then((res) => {
									if(res.code == 0) {
										this.diaLogStudentVisible = false
										this.$message({
											message: "学生添加成功~",
											type: 'success'
										})
										this.$emit("studentAdd")
									}
								})
							}
						})
						break;
				}
			},
			closeStudentDialog() {
				this.diaLogStudentVisible = false;
			},
		}
	};
</script>

<style lang="scss" scoped>
	@import "../../../styles/index.scss";
	@import "./common.scss";
	
	/deep/.el-input__inner {
		height: 40px;
	}
	
	/deep/.el-input .el-input--suffix {
		height: 40px;
	}

	.flex_item {
		display: flex;
		align-items: center;
	}

	.personalTable {
		display: flex;
		flex-direction: column;

		.personalTable_title {
			display: flex;
			line-height: 24px;
			margin: 20px 0 10px 0;

			.personalTable_title_left {
				font-size: 16px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #333333;
				box-sizing: border-box;
				border-bottom: 2px solid $theme_color;
			}

			.personalTable_title_right {
				flex: 1;
				border-bottom: 1px solid #eeeeee;
				display: flex;
				height: 34px;

				.personalTable_btn {
					width: 80px;
					height: 34px;
					background: $theme_color;
					border-radius: 4px;
					margin-left: auto;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 34px;
					color: #ffffff;
					text-align: center;
					cursor: pointer;
				}
			}
		}

		.personalTable_content {
			flex: 1;
			margin-top: 10px;
		}

		.personalTable_dialog {
			.handle_form {
				.handle_form_row {
					margin-top: 25px;
					// height: 40px;
					display: flex;
					// padding: 0 23px;

					.handle_form_item {
						flex: 1;
						display: flex;
						position: relative;

						.handle_form_select {
							width: 400px;
							line-height: 40px;
						}

						.handle_form_ipt {
							width: 400px;
							border: 1px solid #EEEEEE;
							outline: none;
							font-size: 14px;
							font-family: Source Han Sans CN;
							font-weight: 400;
							line-height: 40px;
							color: #000000;
							padding: 0 10px;
							box-sizing: border-box;
						}

						.handle_form_tip {
							position: absolute;
							left: 0px;
							bottom: -10px;
							font-size: 14px;
							font-family: Source Han Sans CN;
							font-weight: 400;
							line-height: 20px;
							color: #999999;
							text-align: right;
						}
					}
				}

				.handle_form_btn {
					display: flex;
					align-items: center;
					margin: 40px 0 0px 0;

					.handle_form_cancel {
						margin-left: auto;
						width: 76px;
						line-height: 34px;
						background: #FFFFFF;
						border: 1px solid $theme_color;
						border-radius: 4px;
						font-size: 14px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: $theme_color;
						text-align: center;
						cursor: pointer;
						margin-right: 16px;
					}

					.handle_form_confirm {
						width: 76px;
						line-height: 34px;
						background: $theme_color;
						border-radius: 4px;
						font-size: 14px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #FFF;
						text-align: center;
						cursor: pointer;
					}
				}
			}
		}
	}
</style>
