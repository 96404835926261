<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 18:10:10
 * @LastEditTime: 2022-06-23 15:56:16
-->
<template>
	<div class="studentInformation">
		<div class="studentInformation_tab">
			<module-list :moduleList="moduleList" :currentTab="currentTab" @changeTab="changeTab" />
		</div>
		<div class="studentInformation_content">
			<!-- 学生列表 -->
			<div class="studentInformation_content_left">
				<student-list :studentIndex="studentIndex" :intList="studentList" @selectStudent="selectStudent"
					@searchStudent="searchStudent" />
			</div>
			<!-- 基础信息表 -->
			<div v-if="currentTab == '基础信息表'" class="studentInformation_content_right">
				<div class="flex_between">
					<tab-list @changeViews="changeViews" :tabList="basicTabList" :currentTab="currentBasic" />
					<div @click="handleForm('add')" v-if="currentBasic == 1" class="content_right_btn">
						<span>+添加学生</span>
					</div>
				</div>
				<div class="content_right_table">
					<!-- 个人资料 -->
					<personal-table @studentAdd="studentAdd" :studentId="studentId" ref="personalTable"
						v-if="currentBasic == 1" />
					<!-- 家庭资料 -->
					<family-table :studentId="studentId" ref="familyTable" v-if="currentBasic == 2" />
					<!-- 教育信息 -->
					<education-table :studentId="studentId" ref="educationTable" v-if="currentBasic == 3" />
					<!-- 标化成绩 -->
					<grade-table :studentId="studentId" ref="gradeTable" v-if="currentBasic == 4" />
					<!-- 奖项信息 -->
					<awards-table :studentId="studentId" ref="awardsTable" v-if="currentBasic == 5" />
					<!-- 活动信息 -->
					<activity-table :studentId="studentId" ref="activityTable" v-if="currentBasic == 6" />
				</div>
			</div>
			<!-- 账号信息表 -->
			<div v-if="currentTab == '账号信息表'" class="studentInformation_content_right">
				<div class="flex_between">
					<tab-list @changeViews="changeViews" :tabList="accountTabList" :currentTab="currentAccount" />
				</div>
				<div class="content_right_table">
					<account-table ref="accountTable" :studentId="studentId" />
				</div>
			</div>
			<!-- 文件库 -->
			<div v-if="currentTab == '文件库'" class="studentInformation_content_right">
				<file-library ref="fileLibrary" :studentId="studentId" />
			</div>
		</div>
	</div>
</template>
<script>
	import moduleList from "@/components/moduleList/index.vue";
	import tabList from "@/components/tabList/index.vue";
	import studentList from "@/components/studentList/index.vue";
	import personalTable from "./components/personalTable.vue";
	import familyTable from "./components/familyTable.vue";
	import educationTable from "./components/educationTable.vue";
	import gradeTable from "./components/gradeTable.vue";
	import awardsTable from "./components/awardsTable.vue";
	import activityTable from "./components/activityTable.vue";
	import accountTable from "./components/accountTable.vue";
	import fileLibrary from "./components/fileLibrary.vue";
	import commonFn from "@/utils/common.js";
	import {
		listStudents,
	} from "@/api/common"
	import storage from 'store'
	import {
		mapActions
	} from 'vuex'
	export default {
		components: {
			moduleList,
			tabList,
			studentList,
			personalTable,
			familyTable,
			educationTable,
			gradeTable,
			awardsTable,
			activityTable,
			accountTable,
			fileLibrary
		},
		data() {
			return {
				currentTab: '',
				moduleList: [],
				studentList: [],
				basicTabList: [{
						label: '个人资料',
						value: '1'
					},
					{
						label: '家庭资料',
						value: '2'
					},
					{
						label: '教育信息',
						value: '3'
					},
					{
						label: '标化成绩',
						value: '4'
					},
					{
						label: '奖项信息',
						value: '5'
					},
					{
						label: '活动信息',
						value: '6'
					},
				],
				currentBasic: '1',
				accountTabList: [{
					label: '账号信息',
					value: 1
				}, ],
				currentAccount: '1',
				mainTeachers: [{
						label: "张三",
						value: "1"
					},
					{
						label: "李四",
						value: "2"
					}
				],
				counselorTeachers: [{
						label: "王五",
						value: "3"
					},
					{
						label: "赵二麻子",
						value: "4"
					}
				],
				studentName: "",
				studentId: "",
				inTab: '',
				studentIndex: 0
			}
		},
		destroyed() {
			// console.log("beforeDestroy")
			storage.remove('currentTab')
		},
		watch: {
			$route: {
				handler(val) {
					console.log(val)
					if (val.query.label) {
						this.currentTab = "基础信息表"
						this.currentBasic = "4"
						this.studentLists()
						this.studentId = val.query.studentId
						let params = {
							studentInfoId: val.query.studentId
						}
						switch (val.query.label) {
							case "1":
								this.inTab = "托福"
								break
							case "2":
								this.inTab = "雅思"
								break
							case "3":
								this.inTab = "多邻国"
								break
							case "4":
								this.inTab = "SAT"
								break
							case "5":
								this.inTab = "SAT2"
								break
							case "6":
								this.inTab = "AP"
								break
							case "7":
								this.inTab = "ALevel"
								break
							case "8":
								this.inTab = "IB"
								break
							case "9":
								this.inTab = "GRE"
								break
							case "10":
								this.inTab = "GMAT"
								break
						}
						this.$nextTick(() => {
							this.$refs.gradeTable.currentTab = this.inTab
							this.$refs.gradeTable.differentTabFn(this.inTab, "tableList", params)
						})
					}
				},
				immediate: true
			},
		},
		async created() {
			console.log("学生信息路由>>>>", this.$route)
			this.moduleList = []
			if (this.$route.meta.secondaryMenu) {
				let secondaryMenu = this.$route.meta.secondaryMenu
				this.moduleList = commonFn.secondaryMenu(secondaryMenu)
				if (storage.get('currentTab')) {
					this.currentTab = storage.get('currentTab')
				} else {
					this.currentTab = this.moduleList[0].label
				}
			}
			await this.studentLists()
			switch (this.currentTab) {
				case "基础信息表":
					this.changeStudentId(this.currentBasic)
					break;
				case "账号信息表":
					this.$nextTick(() => {
						this.$refs.accountTable.getStudentAccountData()
						this.$refs.accountTable.studentPersonalView()
					})
					break;
				case "文件库":
					this.$nextTick(() => {
						this.$refs.fileLibrary.getStudentFileLists()
					})
					break;
			}
		},
		methods: {
			...mapActions(['studentUpdateClear']),
			studentAdd() {
				this.studentLists()
			},
			//获取学生列表
			studentLists() {
				return new Promise((resolve, reject) => {
					var params = {
						name: this.studentName
					}
					listStudents(params).then(res => {
						if (res.code == 0) {
							if (res.data && res.data.length > 0) {
								this.studentList = res.data
								if (this.$route.query.studentId) {
									console.log(this.$route.query, "this.$route.query")
									this.studentId = this.$route.query.studentId
									console.log(this.studentId)
									// this.name = res.data.filter(item => item.studentAccountId == this.studentId)[0].name
									this.studentIndex = res.data.findIndex(item => item.studentAccountId ==
										this.studentId)
									storage.set('studentIndex', this.studentIndex)
									if (this.studentList[this.studentIndex].updateNotifyId) {
										this.studentUpdateClear({
											updateNotifyId: this.studentList[this.studentIndex]
												.updateNotifyId
										}).then((res) => {
											if (res.code == 0) {
												this.studentList[this.studentIndex]
													.updateNotifyId = undefined
												this.$forceUpdate()
											}
										})
									}
								} else {
									if(storage.get('studentId')) {
										let Index = res.data.findIndex(item => item.studentAccountId == storage.get('studentId'))
										// console.log(Index,Index)
										if(Index==-1){
											this.studentIndex = 0
											this.studentId = res.data[this.studentIndex].studentAccountId
										}else{
											this.studentId = storage.get('studentId')
											this.studentIndex = res.data.findIndex(item => item.studentAccountId == this.studentId)
										}
									}else{
										if(storage.get('studentIndex')) {
											this.studentIndex = storage.get('studentIndex') * 1
										}
										this.studentId = res.data[this.studentIndex].studentAccountId;
									}
									// if(storage.get('studentIndex')) {
									// 	this.studentIndex = storage.get('studentIndex') * 1
									// }
									// this.studentId = res.data[this.studentIndex].studentAccountId
									if (this.studentList[this.studentIndex].updateNotifyId) {
										this.studentUpdateClear({
											updateNotifyId: this.studentList[this.studentIndex].updateNotifyId
										}).then((res) => {
											if (res.code == 0) {
												this.studentList[this.studentIndex].updateNotifyId = undefined
												this.$forceUpdate()
											}
										})
									}
									resolve(this.studentList)
									resolve(this.studentId)
								}
							}
						}
					})
				})
			},
			//搜索学生
			searchStudent(data) {
				this.studentName = data
				this.studentLists()
			},
			changeTab(props) {
				console.log("changeTab", props)
				this.currentTab = props.label
				storage.set('currentTab', this.currentTab, 7 * 24 * 60 * 60 * 1000)
				switch (this.currentTab) {
					case "基础信息表":
						this.changeStudentId(this.currentBasic)
						break;
					case "账号信息表":
						if (!this.studentId) return
						this.$nextTick(() => {
							this.$refs.accountTable.getStudentAccountData()
							this.$refs.accountTable.studentPersonalView()
						})
						break;
					case "文件库":
						if (!this.studentId) return
						this.$nextTick(() => {
							this.$refs.fileLibrary.getStudentFileLists()
						})
						break;
				}
			},
			selectStudent(props) {
				console.log("selectStudent", props)
				this.studentId = props.studentAccountId
				this.studentIndex = this.studentList.findIndex(item => item.studentAccountId == this.studentId)
				storage.set('studentIndex', this.studentIndex)
				storage.set('studentId', this.studentId)
				if (this.currentTab == "基础信息表") {
					this.changeStudentId(this.currentBasic)
				} else if (this.currentTab == "账号信息表") {
					this.$nextTick(() => {
						this.$refs.accountTable.getStudentAccountData()
						this.$refs.accountTable.studentPersonalView()
					})
				} else if (this.currentTab == "文件库") {
					this.$nextTick(() => {
						this.$refs.fileLibrary.getStudentFileLists()
					})
				}
			},
			changeViews(item) {
				this.currentBasic = item.value
				console.log("changeViews", item.value)
				this.changeStudentId(this.currentBasic)
			},
			changeStudentId(intTab) {
				if (!this.studentId) return
				switch (intTab) {
					case "1":
						this.$nextTick(() => {
							this.$refs.personalTable.studentPersonalView()
						})
						break;
					case "2":
						this.$nextTick(() => {
							this.$refs.familyTable.studenFamilyView()
						})
						break;
					case "3":
						this.$nextTick(() => {
							this.$refs.educationTable.studenEducationView()
						})
						break;
					case "4":
						this.$nextTick(() => {
							this.$refs.gradeTable.studentsGradeView()
							this.$refs.gradeTable.studentsGradeGpa()
						})
						break;
					case "5":
						this.$nextTick(() => {
							this.$refs.awardsTable.studentsAwardView()
						})
						break;
					case "6":
						this.$nextTick(() => {
							this.$refs.activityTable.studentsActivityView()
						})
						break;
				}
			},
			handleForm(type) {
				switch (type) {
					case "add":
						this.$nextTick(() => {
							this.$refs.personalTable.texcherList()
							this.$refs.personalTable.diaLogStudentTitle = "添加学生";
							this.$refs.personalTable.diaLogStudentVisible = true;
						})
						break;
				}
			},
		},
	}
</script>
<style lang="scss" scoped>
	@import "../../styles/index.scss";

	input::-webkit-input-placeholder {
		font-size: 14px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		line-height: 20px;
		color: #999999 !important;
	}

	.flex_between {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.flex_item {
		display: flex;
		align-items: center;
	}

	.studentInformation {
		display: flex;
		flex-direction: column;

		.studentInformation_tab {
			padding-left: 476px;
			background-color: #F7F7F7;
			margin-top: 4px;
		}

		.studentInformation_content {
			background-color: #ffffff;
			padding: 20px 40px;
			display: flex;

			.studentInformation_content_left {
				margin-right: 20px;
			}

			.studentInformation_content_right {
				flex: 1;
				display: flex;
				flex-direction: column;
				border-radius: 4px;
				position: relative;

				.content_right_btn {
					width: 120px;
					border: 1px solid $theme_color;
					border-radius: 2px;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 36px;
					color: $theme_color;
					text-align: center;
					cursor: pointer;
				}

				.content_right_table {
					flex: 1;
				}
			}
		}
	}
</style>
