<template>
	<div class="gradeTable">
		<div class="gradeTable_gpa">
			<span class="gradeTable_gpa_label">GPA数值:</span>
			<div class="gradeTable_gpa_list">
				<span v-for="(item, index) in gapData" :key="index"
					class="gradeTable_gpa_item">{{item.gpaValue}}（{{item.gpaDate}}）</span>
				<span @click="handleForm('gpa')" class="gradeTable_gpa_btn">
					<img src="@/assets/images/dashboard/add.png">添加GPA
				</span>
			</div>
		</div>
		<div class="gradeTable_tab">
			<el-tabs v-model="currentTab" @tab-click="changeTab">
				<el-tab-pane v-for="(item,index) in tabList" :key="index" :label="item.label" :name="item.label">
				</el-tab-pane>
			</el-tabs>
		</div>
		<!-- 添加科目/考试 -->
		<div class="gradeTable_handle_btn">
			<span @click="handleForm('table')" class="gradeTable_add_btn">
				<img src="@/assets/images/dashboard/add.png">添加考试
			</span>
			<span v-if="currentTab == 'SAT2' || currentTab == 'ALevel'" style="margin-left: 10px;"
				@click="handleForm('subject')" class="gradeTable_add_btn">
				<img src="@/assets/images/dashboard/add.png">添加科目
			</span>
		</div>
		<div class="gradeTable_table">
			<div class="gradeTable_table_box">
				<!-- 托福/雅思 -->
				<el-table v-if="currentTab == '托福' || currentTab == '雅思'" class="gradeTable_table_inner"
					:header-cell-style="headStyle" :cell-style="rowStyle" :data="tableData" border>
					<el-table-column prop="date" label="考试时间">
						<template slot-scope="scope">
							<div class="gradeTable_table_date">
								<span class="table_date"><i
										class="table_date_icon el-icon-date"></i>{{scope.row.examDate?scope.row.examDate: '请选择'}}</span>
								<el-date-picker @change="dateSelect($event, 'examDate', scope.row)"
									v-model="scope.row.examDate" type="date" placeholder="选择日期">
								</el-date-picker>
							</div>
						</template>

					</el-table-column>
					<el-table-column prop="grossScore" :label="currentTab == '托福'?'总分(120)':'总分(9)'">
						<template slot-scope="scope">
							<input placeholder="请输入总分" class="gradeTable_table_ipt" type=""
								v-model="scope.row.grossScore" />
						</template>
					</el-table-column>
					<el-table-column prop="readingScore" :label="currentTab == '托福'?'阅读(30)':'阅读(9)'">
						<template slot-scope="scope">
							<input placeholder="请输入阅读成绩" class="gradeTable_table_ipt" type=""
								v-model="scope.row.readingScore" />
						</template>
					</el-table-column>
					<el-table-column prop="hearingScore" :label="currentTab == '托福'?'听力(30)':'听力(9)'">
						<template slot-scope="scope">
							<input placeholder="请输入听力成绩" class="gradeTable_table_ipt" type=""
								v-model="scope.row.hearingScore" />
						</template>
					</el-table-column>
					<el-table-column prop="speakingScore" :label="currentTab == '托福'?'口语(30)':'口语(9)'">
						<template slot-scope="scope">
							<input placeholder="请输入口语成绩" class="gradeTable_table_ipt" type=""
								v-model="scope.row.speakingScore" />
						</template>
					</el-table-column>
					<el-table-column prop="writingScore" :label="currentTab == '托福'?'写作(30)':'写作(9)'">
						<template slot-scope="scope">
							<input placeholder="请输入写作成绩" class="gradeTable_table_ipt" type=""
								v-model="scope.row.writingScore" />
						</template>
					</el-table-column>
					<el-table-column prop="model" label="考试模式">
						<template slot-scope="scope">
							<div class="gradeTable_table_select">
								<div class="table_select_inner">
									<span class="">{{scope.row.model?modelText(scope.row.model):'请选择'}}</span>
									<img src="@/assets/images/dashboard/moreArrow.png">
								</div>
								<el-select v-model="scope.row.model" placeholder="请选择">
									<el-option v-for="item in patternList" :key="item.value" :label="item.label"
										:value="item.value">
									</el-option>
								</el-select>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="action" label="操作">
						<template slot-scope="scope">
							<div class="gradeTable_table_action">
								<span @click="handleForm('save', scope.row)">保存</span>
								<span @click="handleForm('delete', scope.row)">删除</span>
							</div>
						</template>
					</el-table-column>
				</el-table>
				<!-- 多邻国 -->
				<el-table v-if="currentTab == '多邻国'" class="gradeTable_table_inner" :header-cell-style="headStyle"
					:cell-style="rowStyle" :data="tableData" border>
					<el-table-column prop="date" label="考试时间">
						<template slot-scope="scope">
							<div class="gradeTable_table_date">
								<span class="table_date"><i
										class="table_date_icon el-icon-date"></i>{{scope.row.examDate?scope.row.examDate: '请选择'}}</span>
								<el-date-picker @change="dateSelect($event, 'examDate', scope.row)"
									v-model="scope.row.examDate" type="date" placeholder="选择日期">
								</el-date-picker>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="grossScore" label="总分">
						<template slot-scope="scope">
							<input placeholder="请输入总分" class="gradeTable_table_ipt" type=""
								v-model="scope.row.grossScore" />
						</template>
					</el-table-column>
					<el-table-column prop="literacyScore" label="Literacy（160）">
						<template slot-scope="scope">
							<input placeholder="请输入Literacy成绩" class="gradeTable_table_ipt" type=""
								v-model="scope.row.literacyScore" />
						</template>
					</el-table-column>
					<el-table-column prop="comprehensionScore" label="Comprehension（160）">
						<template slot-scope="scope">
							<input placeholder="请输入Comprehension成绩" class="gradeTable_table_ipt" type=""
								v-model="scope.row.comprehensionScore" />
						</template>
					</el-table-column>
					<el-table-column prop="conversationScore" label="Conversation（160）">
						<template slot-scope="scope">
							<input placeholder="请输入Conversation成绩" class="gradeTable_table_ipt" type=""
								v-model="scope.row.conversationScore" />
						</template>
					</el-table-column>
					<el-table-column prop="productionScore" label="Production（160）">
						<template slot-scope="scope">
							<input placeholder="请输入Production成绩" class="gradeTable_table_ipt" type=""
								v-model="scope.row.productionScore" />
						</template>
					</el-table-column>
					<el-table-column prop="model" label="考试模式">
						<template slot-scope="scope">
							<div class="gradeTable_table_select">
								<div class="table_select_inner">
									<span class="">{{scope.row.model?modelText(scope.row.model):'请选择'}}</span>
									<!-- <img src="@/assets/images/dashboard/moreArrow.png"> -->
								</div>
								<!-- <el-select v-model="scope.row.model" placeholder="请选择">
									<el-option v-for="item in patternList" :key="item.value" :label="item.label"
										:value="item.value">
									</el-option>
								</el-select> -->
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="action" label="操作">
						<template slot-scope="scope">
							<div class="gradeTable_table_action">
								<span @click="handleForm('save', scope.row)">保存</span>
								<span @click="handleForm('delete', scope.row)">删除</span>
							</div>
						</template>
					</el-table-column>
				</el-table>
				<!-- SAT -->
				<el-table v-if="currentTab == 'SAT'" class="gradeTable_table_inner" :header-cell-style="headStyle"
					:cell-style="rowStyle" :data="tableData" border>
					<el-table-column prop="date" label="考试时间">
						<template slot-scope="scope">
							<div class="gradeTable_table_date">
								<span class="table_date"><i
										class="table_date_icon el-icon-date"></i>{{scope.row.examDate?scope.row.examDate: '请选择'}}</span>
								<el-date-picker @change="dateSelect($event, 'examDate', scope.row)"
									v-model="scope.row.examDate" type="date" placeholder="选择日期">
								</el-date-picker>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="grossScore" label="总分（1600）">
						<template slot-scope="scope">
							<input placeholder="请输入总分" class="gradeTable_table_ipt" type=""
								v-model="scope.row.grossScore" />
						</template>
					</el-table-column>
					<el-table-column prop="readingGrammarScore" label="阅读+文法（800）">
						<template slot-scope="scope">
							<input placeholder="请输入阅读成绩" class="gradeTable_table_ipt" type=""
								v-model="scope.row.readingGrammarScore" />
						</template>
					</el-table-column>
					<el-table-column prop="mathScore" label="数学（800）">
						<template slot-scope="scope">
							<input placeholder="请输入听力成绩" class="gradeTable_table_ipt" type=""
								v-model="scope.row.mathScore" />
						</template>
					</el-table-column>
					<el-table-column prop="readingScore" label="写作/Reading（8）">
						<template slot-scope="scope">
							<input placeholder="请输入口语成绩" class="gradeTable_table_ipt" type=""
								v-model="scope.row.readingScore" />
						</template>
					</el-table-column>
					<el-table-column prop="analysisScore" label="写作/Analysis（8）">
						<template slot-scope="scope">
							<input placeholder="请输入写作成绩" class="gradeTable_table_ipt" type=""
								v-model="scope.row.analysisScore" />
						</template>
					</el-table-column>
					<el-table-column prop="writingScore" label="写作/Writing（8）">
						<template slot-scope="scope">
							<input placeholder="请输入写作成绩" class="gradeTable_table_ipt" type=""
								v-model="scope.row.writingScore" />
						</template>
					</el-table-column>
					<el-table-column prop="model" label="考试模式">
						<template slot-scope="scope">
							<div class="gradeTable_table_select">
								<div class="table_select_inner">
									<span class="">{{scope.row.model?modelText(scope.row.model):'请选择'}}</span>
									<img src="@/assets/images/dashboard/moreArrow.png">
								</div>
								<el-select v-model="scope.row.model" placeholder="请选择">
									<el-option v-for="item in patternList" :key="item.value" :label="item.label"
										:value="item.value">
									</el-option>
								</el-select>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="action" label="操作">
						<template slot-scope="scope">
							<div class="gradeTable_table_action">
								<span @click="handleForm('save', scope.row)">保存</span>
								<span @click="handleForm('delete', scope.row)">删除</span>
							</div>
						</template>
					</el-table-column>
				</el-table>
				<!-- SAT2 -->
				<el-table v-if="currentTab == 'SAT2'" class="gradeTable_table_inner" :header-cell-style="headStyle"
					:cell-style="rowStyle" :data="tableData" border>
					<el-table-column prop="date" label="考试时间">
						<template slot-scope="scope">
							<div class="gradeTable_table_date">
								<span class="table_date"><i
										class="table_date_icon el-icon-date"></i>{{scope.row.examDate?scope.row.examDate: '请选择'}}</span>
								<el-date-picker @change="dateSelect($event, 'examDate', scope.row)"
									v-model="scope.row.examDate" type="date" placeholder="选择日期">
								</el-date-picker>
							</div>
						</template>
					</el-table-column>
					<el-table-column v-for="(item,index) in sat2SubjectData" :key="index"
						:prop="'subjectScore'+item.standardSat2ExtendId" :label="item.subjectName">
						<template slot-scope="scope">
							<input v-model="scope.row['subjectScore'+item.standardSat2ExtendId]" placeholder="请输入科目成绩"
								class="gradeTable_table_ipt" type="" />
						</template>
					</el-table-column>
					<el-table-column prop="model" label="考试模式">
						<template slot-scope="scope">
							<div class="gradeTable_table_select">
								<div class="table_select_inner">
									<span class="">{{scope.row.model?modelText(scope.row.model):'请选择'}}</span>
									<img src="@/assets/images/dashboard/moreArrow.png">
								</div>
								<el-select v-model="scope.row.model" placeholder="请选择">
									<el-option v-for="item in patternList" :key="item.value" :label="item.label"
										:value="item.value">
									</el-option>
								</el-select>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="action" label="操作">
						<template slot-scope="scope">
							<div class="gradeTable_table_action">
								<span @click="handleForm('save', scope.row)">保存</span>
								<span @click="handleForm('delete', scope.row)">删除</span>
							</div>
						</template>
					</el-table-column>
				</el-table>
				<!-- AP -->
				<el-table v-if="currentTab == 'AP'" class="gradeTable_table_inner" :header-cell-style="headStyle"
					:cell-style="rowStyle" :data="tableData" border>
					<el-table-column prop="date" label="考试时间">
						<template slot-scope="scope">
							<div class="gradeTable_table_date">
								<span class="table_date"><i
										class="table_date_icon el-icon-date"></i>{{scope.row.examDate?scope.row.examDate: '请选择'}}</span>
								<el-date-picker @change="dateSelect($event, 'examDate', scope.row)"
									v-model="scope.row.examDate" type="date" placeholder="选择日期">
								</el-date-picker>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="subjectName" label="科目">
						<template slot-scope="scope">
							<input placeholder="请输入科目" class="gradeTable_table_ipt" type=""
								v-model="scope.row.subjectName" />
						</template>
					</el-table-column>
					<el-table-column prop="subjectScore" label="分数">
						<template slot-scope="scope">
							<input placeholder="请输入分数" class="gradeTable_table_ipt" type=""
								v-model="scope.row.subjectScore" />
						</template>
					</el-table-column>
					<el-table-column prop="model" label="考试模式">
						<template slot-scope="scope">
							<div class="gradeTable_table_select">
								<div class="table_select_inner">
									<span class="">{{scope.row.model?modelText(scope.row.model):'请选择'}}</span>
									<img src="@/assets/images/dashboard/moreArrow.png">
								</div>
								<el-select v-model="scope.row.model" placeholder="请选择">
									<el-option v-for="item in patternList" :key="item.value" :label="item.label"
										:value="item.value">
									</el-option>
								</el-select>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="action" label="操作">
						<template slot-scope="scope">
							<div class="gradeTable_table_action">
								<span @click="handleForm('save', scope.row)">保存</span>
								<span @click="handleForm('delete', scope.row)">删除</span>
							</div>
						</template>
					</el-table-column>
				</el-table>
				<!-- ALevel -->
				<el-table v-if="currentTab == 'ALevel'" class="gradeTable_table_inner" :header-cell-style="headStyle"
					:cell-style="rowStyle" :data="tableData" border>
					<el-table-column prop="date" label="考试时间">
						<template slot-scope="scope">
							<div class="gradeTable_table_date">
								<span class="table_date"><i
										class="table_date_icon el-icon-date"></i>{{scope.row.examDate?dateFormatMonth(scope.row.examDate): '请选择'}}</span>
								<el-date-picker @change="dateSelect($event, 'examDate', scope.row)"
									v-model="scope.row.examDate" type="month" placeholder="选择日期">
								</el-date-picker>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="type" label="考试类型">
						<template slot-scope="scope">
							<div class="gradeTable_table_select">
								<div class="table_select_inner">
									<span class="">{{scope.row.type?typeText(scope.row.type):'请选择'}}</span>
									<img src="@/assets/images/dashboard/moreArrow.png">
								</div>
								<el-select v-model="scope.row.type" placeholder="请选择">
									<el-option v-for="item in typeList" :key="item.value" :label="item.label"
										:value="item.value">
									</el-option>
								</el-select>
							</div>
						</template>
					</el-table-column>
					<el-table-column v-for="(item,index) in aLevelSubjectData" :key="index"
						:prop="'subjectScore'+item.standardAlevelExtendId" :label="item.subjectName">
						<template slot-scope="scope">
							<input v-model="scope.row['subjectScore'+item.standardAlevelExtendId]" placeholder="请输入科目成绩"
								class="gradeTable_table_ipt" type="" />
						</template>
					</el-table-column>
					<el-table-column prop="action" label="操作">
						<template slot-scope="scope">
							<div class="gradeTable_table_action">
								<span @click="handleForm('save', scope.row)">保存</span>
								<span @click="handleForm('delete', scope.row)">删除</span>
							</div>
						</template>
					</el-table-column>
				</el-table>
				<!-- IB -->
				<el-table v-if="currentTab == 'IB'" class="gradeTable_table_inner" :header-cell-style="headStyle"
					:cell-style="rowStyle" :data="tableData" border>
					<el-table-column prop="date" label="考试时间">
						<template slot-scope="scope">
							<div class="gradeTable_table_date">
								<span class="table_date"><i
										class="table_date_icon el-icon-date"></i>{{scope.row.examDate?scope.row.examDate: '请选择'}}</span>
								<el-date-picker @change="dateSelect($event, 'examDate', scope.row)"
									v-model="scope.row.examDate" type="date" placeholder="选择日期">
								</el-date-picker>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="type" label="分数类型">
						<template slot-scope="scope">
							<div class="gradeTable_table_value">
								<div v-for="(item,index) in scope.row.type" :key="index" class="table_value_h360">
									{{item == '1'? 'IB Predict Score' : 'IB Actual Score'}}
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="grossScore" label="总分">
						<template slot-scope="scope">
							<div class="gradeTable_table_value">
								<div v-for="(item,index) in scope.row.grossScore" :key="index" class="table_value_h360">
									<input placeholder="请输入总分" class="gradeTable_table_ipt360" type=""
										v-model="item.value" />
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="tokEe" label="TOK+EE">
						<template slot-scope="scope">
							<div class="gradeTable_table_value">
								<div v-for="(item,index) in scope.row.tokEe" :key="index" class="table_value_h360">
									<input placeholder="请输入TOK+EE" class="gradeTable_table_ipt360" type=""
										v-model="item.value" />
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="subjectName" label="科目">
						<template slot-scope="scope">
							<div class="gradeTable_table_value">
								<div v-for="(item,index) in scope.row.subjectName" :key="index" class="table_value_h60">
									<div :class="{'border_bottom': index != 11}">
										<input placeholder="请输入科目名称" class="gradeTable_table_ipt60" type=""
											v-model="item.value" />
									</div>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="level" label="科目等级">
						<template slot-scope="scope">
							<div v-for="(item,index) in scope.row.level" :key="index" class="table_value_h60">
								<div :class="{'border_bottom': index != 11}" class="gradeTable_table_select">
									<div class="table_select_inner">
										<span
											class="">{{item.value == '1'?'High Level': item.value == '2'? 'Standard Level': '请选择'}}</span>
										<img src="@/assets/images/dashboard/moreArrow.png">
									</div>
									<el-select v-model="item.value" placeholder="请选择">
										<el-option v-for="item in levelList" :key="item.value" :label="item.label"
											:value="item.value">
										</el-option>
									</el-select>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="subjectScore" label="分数">
						<template slot-scope="scope">
							<div class="gradeTable_table_value">
								<div v-for="(item,index) in scope.row.subjectScore" :key="index"
									class="table_value_h60">
									<div :class="{'border_bottom': index != 11}">
										<input placeholder="请输入分数" class="gradeTable_table_ipt60" type=""
											v-model="item.value" />
									</div>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="action" label="操作">
						<template slot-scope="scope">
							<div class="gradeTable_table_action">
								<span @click="handleForm('save', scope.row)">保存</span>
								<span @click="handleForm('delete', scope.row)">删除</span>
							</div>
						</template>
					</el-table-column>
				</el-table>
				<!-- GRE -->
				<el-table v-if="currentTab == 'GRE'" class="gradeTable_table_inner" :header-cell-style="headStyle"
					:cell-style="rowStyle" :data="tableData" border>
					<el-table-column prop="date" label="考试时间">
						<template slot-scope="scope">
							<div class="gradeTable_table_date">
								<span class="table_date"><i
										class="table_date_icon el-icon-date"></i>{{scope.row.examDate?scope.row.examDate: '请选择'}}</span>
								<el-date-picker @change="dateSelect($event, 'examDate', scope.row)"
									v-model="scope.row.examDate" type="date" placeholder="选择日期">
								</el-date-picker>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="grossScore" label="Verbal Reasoning + Quantitative Reasoning（总分340）">
						<template slot-scope="scope">
							<input placeholder="请输入总分" class="gradeTable_table_ipt" type=""
								v-model="scope.row.grossScore" />
						</template>
					</el-table-column>
					<el-table-column prop="verbal" label="Verbal Reasoning (满分170分)+百分比（%）">
						<template slot-scope="scope">
							<div class="flex_item">
								<input placeholder="请输入" class="gradeTable_table_ipt" type=""
									v-model="scope.row.verbal" />
								<input placeholder="请输入" class="gradeTable_table_ipt border_left" type=""
									v-model="scope.row.verbalPercent" />
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="quantitative" label="Quantitative Reasoning (满分170分)+百分比（%）">
						<template slot-scope="scope">
							<div class="flex_item">
								<input placeholder="请输入" class="gradeTable_table_ipt" type=""
									v-model="scope.row.quantitative" />
								<input placeholder="请输入" class="gradeTable_table_ipt border_left" type=""
									v-model="scope.row.quantitativePercent" />
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="analytical" label="Analytical Writing (满分6分)+百分比（%）">
						<template slot-scope="scope">
							<div class="flex_item">
								<input placeholder="请输入" class="gradeTable_table_ipt" type=""
									v-model="scope.row.analytical" />
								<input placeholder="请输入" class="gradeTable_table_ipt border_left" type=""
									v-model="scope.row.analyticalPercent" />
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="writingScore" label="写作">
						<template slot-scope="scope">
							<input placeholder="请输入口语成绩" class="gradeTable_table_ipt" type=""
								v-model="scope.row.writingScore" />
						</template>
					</el-table-column>
					<el-table-column prop="model" label="考试模式">
						<template slot-scope="scope">
							<div class="gradeTable_table_select">
								<div class="table_select_inner">
									<span class="">{{scope.row.model?modelText(scope.row.model):'请选择'}}</span>
									<img src="@/assets/images/dashboard/moreArrow.png">
								</div>
								<el-select v-model="scope.row.model" placeholder="请选择">
									<el-option v-for="item in patternList" :key="item.value" :label="item.label"
										:value="item.value">
									</el-option>
								</el-select>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="action" label="操作">
						<template slot-scope="scope">
							<div class="gradeTable_table_action">
								<span @click="handleForm('save', scope.row)">保存</span>
								<span @click="handleForm('delete', scope.row)">删除</span>
							</div>
						</template>
					</el-table-column>
				</el-table>
				<!-- GMAT -->
				<el-table v-if="currentTab == 'GMAT'" class="gradeTable_table_inner" :header-cell-style="headStyle"
					:cell-style="rowStyle" :data="tableData" border>
					<el-table-column prop="date" label="考试时间">
						<template slot-scope="scope">
							<div class="gradeTable_table_date">
								<span class="table_date"><i
										class="table_date_icon el-icon-date"></i>{{scope.row.examDate?scope.row.examDate: '请选择'}}</span>
								<el-date-picker @change="dateSelect($event, 'examDate', scope.row)"
									v-model="scope.row.examDate" type="date" placeholder="选择日期">
								</el-date-picker>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="grossScore" label="总分（800）">
						<template slot-scope="scope">
							<input placeholder="请输入总分" class="gradeTable_table_ipt" type=""
								v-model="scope.row.grossScore" />
						</template>
					</el-table-column>
					<el-table-column prop="quantitative	" label="Quantitative Reasoning">
						<template slot-scope="scope">
							<input placeholder="请输入" class="gradeTable_table_ipt" type=""
								v-model="scope.row.quantitative	" />
						</template>
					</el-table-column>
					<el-table-column prop="verbal" label="Verbal Reasoning">
						<template slot-scope="scope">
							<input placeholder="请输入" class="gradeTable_table_ipt" type="" v-model="scope.row.verbal" />
						</template>
					</el-table-column>
					<el-table-column prop="analytical" label="Analytical Writing">
						<template slot-scope="scope">
							<input placeholder="请输入" class="gradeTable_table_ipt" type=""
								v-model="scope.row.analytical" />
						</template>
					</el-table-column>
					<el-table-column prop="integrated" label="Integrated Reasoning">
						<template slot-scope="scope">
							<input placeholder="请输入" class="gradeTable_table_ipt" type=""
								v-model="scope.row.integrated" />
						</template>
					</el-table-column>
					<el-table-column prop="model" label="考试模式">
						<template slot-scope="scope">
							<div class="gradeTable_table_select">
								<div class="table_select_inner">
									<span class="">{{scope.row.model?modelText(scope.row.model):'请选择'}}</span>
									<img src="@/assets/images/dashboard/moreArrow.png">
								</div>
								<el-select v-model="scope.row.model" placeholder="请选择">
									<el-option v-for="item in patternList" :key="item.value" :label="item.label"
										:value="item.value">
									</el-option>
								</el-select>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="action" label="操作">
						<template slot-scope="scope">
							<div class="gradeTable_table_action">
								<span @click="handleForm('save', scope.row)">保存</span>
								<span @click="handleForm('delete', scope.row)">删除</span>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<!-- 添加GPA -->
		<div class="gradeTable_dialog">
			<el-dialog :modal="false" class="dialog_bg" :append-to-body="false" :modal-append-to-body="false" @close="closeGpaDialog" width="579px" :title="diaLogGpaTitle" :visible.sync="diaLogGpaVisible">
				<div class="handle_form">
					<el-form ref="gpaDetail" :rules="gpaRules" :model="gpaDetail" label-width="90px">
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item prop="gpaValue" class="flex_item" label="GPA数值:">
									<input v-model="gpaDetail.gpaValue" placeholder="请输入GPA数值"
										class="handle_form_ipt"></input>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item prop="gpaDate" class="flex_item" label="GPA日期:">
									<el-date-picker @change="gpaDateSelect" v-model="gpaDetail.gpaDate"
										class="handle_form_date" type="month" placeholder="选择日期">
									</el-date-picker>
								</el-form-item>
							</div>
						</div>
					</el-form>
					<div class="handle_form_btn">
						<span @click="closeGpaDialog" class="handle_form_cancel">取消</span>
						<span v-preventReClick @click="handleForm('submitGpa')" class="handle_form_confirm">确定</span>
					</div>
				</div>
			</el-dialog>
			<!-- 科目添加 -->
			<el-dialog :modal="false" class="dialog_bg" :append-to-body="false" :modal-append-to-body="false" @close="closeSubjectDialog" width="579px" :title="diaLogSubjectTitle"
				:visible.sync="diaLogSubjectVisible">
				<div class="handle_form">
					<el-form ref="subjectDetail" :rules="subjectRules" :model="subjectDetail" label-width="80px">
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="flex_item" prop="subjectName" label="科目名称:">
									<input v-model="subjectDetail.subjectName" placeholder="请输入科目名称"
										class="handle_form_ipt"></input>
								</el-form-item>
							</div>
						</div>
					</el-form>
					<div class="handle_form_btn">
						<span @click="closeSubjectDialog" class="handle_form_cancel">取消</span>
						<span v-preventReClick @click="handleForm('submitSubject')" class="handle_form_confirm">确定</span>
					</div>
				</div>
			</el-dialog>
		</div>
		<messege-box ref="messegeBox" @confirmMessege="confirmMessege" @closeMessege="closeMessege"
			:isOpen="messegeVisible" :messegeTip="messegeTip" :messegeContent="messegeContent"
			:messegeType="messegeType" />
	</div>
</template>

<script>
import {
  gradeGpaStudents,
  AddStudentGradeGpa,
  gradeTofuStudents,
  AddStudentGradeTofu,
  EditStudentGradeTofu,
  DeleteStudentGradeTofu,
  gradeYasiStudents,
  AddStudentGradeYasi,
  EditStudentGradeYasi,
  DeleteStudentGradeYasi,
  gradeDuolinStudents,
  AddStudentGradeDuolin,
  EditStudentGradeDuolin,
  DeleteStudentGradeDuolin,
  gradeSatStudents,
  AddStudentGradeSat,
  EditStudentGradeSat,
  DeleteStudentGradeSat,
  gradeSat2Students,
  AddStudentGradeSat2,
  EditStudentGradeSat2,
  DeleteStudentGradeSat2,
  gradeSat2SubjectStudents,
  AddStudentGradeSat2Subject,
  gradeApStudents,
  AddStudentGradeAp,
  EditStudentGradeAp,
  DeleteStudentGradeAp,
  gradeALevelStudents,
  AddStudentGradeALevel,
  EditStudentGradeALevel,
  DeleteStudentGradeALevel,
  gradeALevelSubjectStudents,
  AddStudentGradeALevelSubject,
  gradeIbStudents,
  AddStudentGradeIb,
  EditStudentGradeIb,
  DeleteStudentGradeIb,
  gradeGreStudents,
  AddStudentGradeGre,
  EditStudentGradeGre,
  DeleteStudentGradeGre,
  gradeGmatStudents,
  AddStudentGradeGmat,
  EditStudentGradeGmat,
  DeleteStudentGradeGmat
} from "@/api/common";
import commonFn from "@/utils/common.js";
export default {
  data() {
    return {
      reLoad: true,
      currentTab: "托福",
      tabList: [
        {
          label: "托福",
          value: 1
        },
        {
          label: "雅思",
          value: 2
        },
        {
          label: "多邻国",
          value: 3
        },
        {
          label: "SAT",
          value: 4
        },
        {
          label: "SAT2",
          value: 5
        },
        {
          label: "AP",
          value: 6
        },
        {
          label: "ALevel",
          value: 7
        },
        {
          label: "IB",
          value: 8
        },
        {
          label: "GRE",
          value: 9
        },
        {
          label: "GMAT",
          value: 10
        }
      ],
      gapData: [],
      tableData: [],
      sat2SubjectData: [],
      aLevelSubjectData: [],
      patternList: [
        {
          label: "线上",
          value: "1"
        },
        {
          label: "线下",
          value: "2"
        }
      ],
      typeList: [
        {
          label: "IGCES",
          value: "1"
        },
        {
          label: "As Level",
          value: "2"
        },
        {
          label: "A Level",
          value: "3"
        }
      ],
      levelList: [
        {
          label: "High Level",
          value: "1"
        },
        {
          label: "Standard Level",
          value: "2"
        }
      ],
      gpaRules: {
        gpaDate: [
          {
            required: true,
            message: "请选择GPA日期",
            trigger: "change"
          }
        ],
        gpaValue: [
          {
            required: true,
            message: "请输入GPA数值",
            trigger: "change"
          }
        ]
      },
      diaLogGpaTitle: "",
      diaLogGpaVisible: false,
      gpaDetail: {},
      diaLogSubjectTitle: "",
      diaLogSubjectVisible: false,
      subjectDetail: {},
      subjectRules: {
        subjectName: [
          {
            required: true,
            message: "请输入科目名称",
            trigger: "change"
          }
        ]
      },
      deleteObj: {},
      messegeVisible: false,
      messegeTip: "",
      messegeContent: "",
      messegeType: ""
    };
  },
  props: {
    studentId: {
      type: String,
      default: function() {
        return "";
      }
    }
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    headStyle() {
      return "text-align:center;background: #F7F7F7;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;";
    },
    rowStyle() {
      return "text-align:center;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #333333;";
    },
    dateFormatMonth(intDate) {
      return commonFn.timeFormat(intDate, "yyyy-MM");
    },
    // GPA日期选择
    gpaDateSelect(value) {
      this.gpaDetail.gpaDate = commonFn.timeFormat(value, "yyyy-MM-dd");
    },
    // 时间选择
    dateSelect(value, key, item) {
      console.log("dateSelect", value, item);
      item[key] = commonFn.timeFormat(value, "yyyy-MM-dd");
      this.$forceUpdate();
    },
    // 科目赋值
    // subjectIpt(e, item) {
    // 	console.log("subjectIpt", e.target.value, item)
    // 	item.subjectScore = e.target.value
    // 	this.$forceUpdate()
    // },
    // 获取学生GPA
    studentsGradeGpa() {
      let params = {
        studentInfoId: this.studentId
      };
      this.gapData = [];
      gradeGpaStudents(params).then(res => {
        if (res.code == 0) {
          this.gapData = res.data;
        }
      });
    },
    // 获取学生标化成绩
    studentsGradeView() {
      let params = {
        studentInfoId: this.studentId
      };
      this.tableData = [];
      this.differentTabFn(this.currentTab, "tableList", params);
    },
    // 托福成绩操作
    studentsGradeTofuHandle(type, params) {
      if (type == "list") {
        // 托福列表
        gradeTofuStudents(params).then(res => {
          if (res.code == 0) {
            this.tableData = res.data;
          }
        });
      } else if (type == "update") {
        if (params.standardToeflId) {
          // 托福编辑
          EditStudentGradeTofu(params).then(res => {
            if (res.code == 0) {
              this.$message({
                message: "托福成绩编辑成功~",
                type: "success"
              });
              this.studentsGradeView();
            }
          });
        } else {
          // 托福新增
          AddStudentGradeTofu(params).then(res => {
            if (res.code == 0) {
              this.$message({
                message: "托福成绩添加成功~",
                type: "success"
              });
              this.studentsGradeView();
            }
          });
        }
      } else if (type == "delete") {
        // 托福删除
        DeleteStudentGradeTofu(params).then(res => {
          if (res.code == 0) {
            this.$message({
              message: "托福成绩删除成功~",
              type: "success"
            });
            this.messegeVisible = false;
            this.studentsGradeView();
          }
        });
      }
    },
    // 雅思成绩操作
    studentsGradeYasiHandle(type, params) {
      if (type == "list") {
        // 雅思列表
        gradeYasiStudents(params).then(res => {
          if (res.code == 0) {
            this.tableData = res.data;
          }
        });
      } else if (type == "update") {
        if (params.standardIeltsId) {
          // 雅思编辑
          EditStudentGradeYasi(params).then(res => {
            if (res.code == 0) {
              this.$message({
                message: "雅思成绩编辑成功~",
                type: "success"
              });
              this.studentsGradeView();
            }
          });
        } else {
          // 雅思新增
          AddStudentGradeYasi(params).then(res => {
            if (res.code == 0) {
              this.$message({
                message: "雅思成绩添加成功~",
                type: "success"
              });
              this.studentsGradeView();
            }
          });
        }
      } else if (type == "delete") {
        // 雅思删除
        DeleteStudentGradeYasi(params).then(res => {
          if (res.code == 0) {
            this.$message({
              message: "雅思成绩删除成功~",
              type: "success"
            });
            this.messegeVisible = false;
            this.studentsGradeView();
          }
        });
      }
    },
    // 多邻国成绩操作
    studentsGradeDuolinHandle(type, params) {
      if (type == "list") {
        // 多邻国列表
        gradeDuolinStudents(params).then(res => {
          if (res.code == 0) {
            this.tableData = res.data;
          }
        });
      } else if (type == "update") {
        if (params.standardDuolingoId) {
          // 多邻国编辑
          EditStudentGradeDuolin(params).then(res => {
            if (res.code == 0) {
              this.$message({
                message: "多邻国成绩编辑成功~",
                type: "success"
              });
              this.studentsGradeView();
            }
          });
        } else {
          // 多邻国新增
          AddStudentGradeDuolin(params).then(res => {
            if (res.code == 0) {
              this.$message({
                message: "多邻国成绩添加成功~",
                type: "success"
              });
              this.studentsGradeView();
            }
          });
        }
      } else if (type == "delete") {
        // 多邻国删除
        DeleteStudentGradeDuolin(params).then(res => {
          if (res.code == 0) {
            this.$message({
              message: "多邻国成绩删除成功~",
              type: "success"
            });
            this.messegeVisible = false;
            this.studentsGradeView();
          }
        });
      }
    },
    // SAT成绩操作
    studentsGradeSatHandle(type, params) {
      if (type == "list") {
        // SAT列表
        gradeSatStudents(params).then(res => {
          if (res.code == 0) {
            this.tableData = res.data;
          }
        });
      } else if (type == "update") {
        if (params.standardSatId) {
          // SAT编辑
          EditStudentGradeSat(params).then(res => {
            if (res.code == 0) {
              this.$message({
                message: "SAT成绩编辑成功~",
                type: "success"
              });
              this.studentsGradeView();
            }
          });
        } else {
          // SAT新增
          AddStudentGradeSat(params).then(res => {
            if (res.code == 0) {
              this.$message({
                message: "SAT成绩添加成功~",
                type: "success"
              });
              this.studentsGradeView();
            }
          });
        }
      } else if (type == "delete") {
        // SAT删除
        DeleteStudentGradeSat(params).then(res => {
          if (res.code == 0) {
            this.$message({
              message: "SAT成绩删除成功~",
              type: "success"
            });
            this.messegeVisible = false;
            this.studentsGradeView();
          }
        });
      }
    },
    // SAT2成绩操作
    async studentsGradeSat2Handle(type, params) {
      if (type == "list") {
        // SAT2科目列表
        await this.studentsGradeSat2Subject();
        // SAT2列表
        gradeSat2Students(params).then(res => {
          if (res.code == 0) {
            this.tableData = res.data;
            if (this.tableData.length > 0) {
              this.tableData.forEach(itemOne => {
                if (itemOne.subjectScores && itemOne.subjectScores.length > 0) {
                  itemOne.subjectScores.forEach(itemTwo => {
                    this.$set(
                      itemOne,
                      `subjectScore${itemTwo.standardSat2ExtendId}`,
                      itemTwo.subjectScore
                    );
                  });
                }
              });
            }
          }
        });
      } else if (type == "update") {
        if (params.standardSat2Id) {
          // SAT2编辑
          EditStudentGradeSat2(params).then(res => {
            if (res.code == 0) {
              this.$message({
                message: "SAT2成绩编辑成功~",
                type: "success"
              });
              this.studentsGradeView();
            }
          });
        } else {
          // SAT2新增
          AddStudentGradeSat2(params).then(res => {
            if (res.code == 0) {
              this.$message({
                message: "SAT2成绩添加成功~",
                type: "success"
              });
              this.studentsGradeView();
            }
          });
        }
      } else if (type == "delete") {
        // SAT2删除
        DeleteStudentGradeSat2(params).then(res => {
          if (res.code == 0) {
            this.$message({
              message: "SAT2成绩删除成功~",
              type: "success"
            });
            this.messegeVisible = false;
            this.studentsGradeView();
          }
        });
      }
    },
    // 获取SAT2科目
    studentsGradeSat2Subject() {
      this.sat2SubjectData = [];
      return new Promise((resolve, reject) => {
        let params = {
          studentInfoId: this.studentId
        };
        gradeSat2SubjectStudents(params).then(res => {
          if (res.code == 0) {
            this.sat2SubjectData = res.data;
            resolve(this.sat2SubjectData);
          } else {
            reject();
          }
        });
      });
    },
    // Ap成绩操作
    studentsGradeApHandle(type, params) {
      if (type == "list") {
        // Ap列表
        gradeApStudents(params).then(res => {
          if (res.code == 0) {
            this.tableData = res.data;
          }
        });
      } else if (type == "update") {
        if (params.standardApId) {
          // Ap编辑
          EditStudentGradeAp(params).then(res => {
            if (res.code == 0) {
              this.$message({
                message: "Ap成绩编辑成功~",
                type: "success"
              });
              this.studentsGradeView();
            }
          });
        } else {
          // Ap新增
          AddStudentGradeAp(params).then(res => {
            if (res.code == 0) {
              this.$message({
                message: "Ap成绩添加成功~",
                type: "success"
              });
              this.studentsGradeView();
            }
          });
        }
      } else if (type == "delete") {
        // Ap删除
        DeleteStudentGradeAp(params).then(res => {
          if (res.code == 0) {
            this.$message({
              message: "Ap成绩删除成功~",
              type: "success"
            });
            this.messegeVisible = false;
            this.studentsGradeView();
          }
        });
      }
    },
    // Alevel成绩操作
    async studentsGradeALevelHandle(type, params) {
      if (type == "list") {
        // Alevel科目列表
        await this.studentsGradeALevelSubject();
        // Alevel列表
        gradeALevelStudents(params).then(res => {
          if (res.code == 0) {
            if (res.data) {
              this.tableData = res.data;
              if (this.tableData.length > 0) {
                this.tableData.forEach(itemOne => {
                  if (
                    itemOne.subjectScores &&
                    itemOne.subjectScores.length > 0
                  ) {
                    itemOne.subjectScores.forEach(itemTwo => {
                      this.$set(
                        itemOne,
                        `subjectScore${itemTwo.standardAlevelExtendId}`,
                        itemTwo.subjectScore
                      );
                    });
                  }
                });
              }
            }
          }
        });
      } else if (type == "update") {
        if (params.standardAlevelId) {
          // Alevel编辑
          EditStudentGradeALevel(params).then(res => {
            if (res.code == 0) {
              this.$message({
                message: "Alevel成绩编辑成功~",
                type: "success"
              });
              this.studentsGradeView();
            }
          });
        } else {
          // Alevel新增
          AddStudentGradeALevel(params).then(res => {
            if (res.code == 0) {
              this.$message({
                message: "Alevel成绩添加成功~",
                type: "success"
              });
              this.studentsGradeView();
            }
          });
        }
      } else if (type == "delete") {
        // Alevel删除
        DeleteStudentGradeALevel(params).then(res => {
          if (res.code == 0) {
            this.$message({
              message: "Alevel成绩删除成功~",
              type: "success"
            });
            this.messegeVisible = false;
            this.studentsGradeView();
          }
        });
      }
    },
    // 获取Alevel科目
    studentsGradeALevelSubject() {
      this.aLevelSubjectData = [];
      return new Promise((resolve, reject) => {
        let params = {
          studentInfoId: this.studentId
        };
        gradeALevelSubjectStudents(params).then(res => {
          if (res.code == 0) {
            this.aLevelSubjectData = res.data;
            resolve(this.aLevelSubjectData);
          } else {
            reject();
          }
        });
      });
    },
    // Ib成绩操作
    studentsGradeIbHandle(type, params) {
      if (type == "list") {
        // Ib列表
        gradeIbStudents(params).then(res => {
          if (res.code == 0) {
            if (res.data) {
              let resData = res.data;
              let resKeys = Object.keys(resData);
              let newArr = [];
              console.log("resKeys", resKeys);
              if (resKeys && resKeys.length > 0) {
                resKeys.forEach(itemOne => {
                  let newObj = {
                    recordNumber: "",
                    standardIbId: "",
                    studentAccountId: "",
                    examDate: "",
                    type: [],
                    grossScore: [],
                    tokEe: [],
                    subjectName: [],
                    level: [],
                    subjectScore: []
                  };
                  resData[itemOne].forEach(async itemTwo => {
                    newObj.examDate = itemTwo.examDate;
                    newObj.recordNumber = itemTwo.recordNumber;
                    newObj.standardIbId = itemTwo.standardIbId;
                    newObj.studentAccountId = itemTwo.studentAccountId;
                    newObj.type.push(itemTwo.type);
                    newObj.type = [...new Set(newObj.type)];
                    newObj.grossScore.push({
                      type: itemTwo.type,
                      value: itemTwo.grossScore
                    });
                    newObj.grossScore = await this.reduceArr(newObj.grossScore);
                    newObj.tokEe.push({
                      type: itemTwo.type,
                      value: itemTwo.tokEe
                    });
                    newObj.tokEe = await this.reduceArr(newObj.tokEe);
                    newObj.subjectName.push({
                      type: itemTwo.type,
                      value: itemTwo.subjectName,
                      standardIbId: itemTwo.standardIbId
                    });
                    newObj.level.push({
                      type: itemTwo.type,
                      value: itemTwo.level
                    });
                    newObj.subjectScore.push({
                      type: itemTwo.type,
                      value: itemTwo.subjectScore
                    });
                  });
                  newArr.push(newObj);
                });
              }
              console.log("newArr", newArr);
              this.tableData = newArr;
            }
          }
        });
      } else if (type == "update") {
        if (params.standardIbId) {
          // Ib编辑
          EditStudentGradeIb(params.paramsArr).then(res => {
            if (res.code == 0) {
              this.$message({
                message: "Ib成绩编辑成功~",
                type: "success"
              });
              this.studentsGradeView();
            }
          });
        } else {
          // Ib新增
          AddStudentGradeIb(params.paramsArr).then(res => {
            if (res.code == 0) {
              this.$message({
                message: "Ib成绩添加成功~",
                type: "success"
              });
              this.studentsGradeView();
            }
          });
        }
      } else if (type == "delete") {
        // Ib删除
        DeleteStudentGradeIb(params).then(res => {
          if (res.code == 0) {
            this.$message({
              message: "Ib成绩删除成功~",
              type: "success"
            });
            this.messegeVisible = false;
            this.studentsGradeView();
          }
        });
      }
    },
    // Gre成绩操作
    studentsGradeGreHandle(type, params) {
      if (type == "list") {
        // Gre列表
        gradeGreStudents(params).then(res => {
          if (res.code == 0) {
            this.tableData = res.data;
          }
        });
      } else if (type == "update") {
        if (params.standardGreId) {
          // Gre编辑
          EditStudentGradeGre(params).then(res => {
            if (res.code == 0) {
              this.$message({
                message: "GRE成绩编辑成功~",
                type: "success"
              });
              this.studentsGradeView();
            }
          });
        } else {
          // Gre新增
          AddStudentGradeGre(params).then(res => {
            if (res.code == 0) {
              this.$message({
                message: "GRE成绩添加成功~",
                type: "success"
              });
              this.studentsGradeView();
            }
          });
        }
      } else if (type == "delete") {
        // Gre删除
        DeleteStudentGradeGre(params).then(res => {
          if (res.code == 0) {
            this.$message({
              message: "GRE成绩删除成功~",
              type: "success"
            });
            this.messegeVisible = false;
            this.studentsGradeView();
          }
        });
      }
    },
    // Gmat成绩操作
    studentsGradeGmatHandle(type, params) {
      if (type == "list") {
        // Gmat列表
        gradeGmatStudents(params).then(res => {
          if (res.code == 0) {
            this.tableData = res.data;
          }
        });
      } else if (type == "update") {
        if (params.standardGmatId) {
          // Gmat编辑
          EditStudentGradeGmat(params).then(res => {
            if (res.code == 0) {
              this.$message({
                message: "Gmat成绩编辑成功~",
                type: "success"
              });
              this.studentsGradeView();
            }
          });
        } else {
          // Gmat新增
          AddStudentGradeGmat(params).then(res => {
            if (res.code == 0) {
              this.$message({
                message: "Gmat成绩添加成功~",
                type: "success"
              });
              this.studentsGradeView();
            }
          });
        }
      } else if (type == "delete") {
        // Gmat删除
        DeleteStudentGradeGmat(params).then(res => {
          if (res.code == 0) {
            this.$message({
              message: "Gmat成绩删除成功~",
              type: "success"
            });
            this.messegeVisible = false;
            this.studentsGradeView();
          }
        });
      }
    },
    differentTabFn(intTab, type, params) {
      switch (intTab) {
        case "托福":
          if (type == "tableList") {
            this.studentsGradeTofuHandle("list", params);
          } else if (type == "tableSave") {
            this.studentsGradeTofuHandle("update", params);
          } else if (type == "tabelDelete") {
            this.studentsGradeTofuHandle("delete", params);
          } else {
            let formObj = {
              studentAccountId: this.studentId,
              examDate: "",
              grossScore: "",
              readingScore: "",
              hearingScore: "",
              speakingScore: "",
              writingScore: "",
              model: "",
              current: "newAdd" + this.tableData.length
            };
            this.tableData.push(formObj);
          }
          break;
        case "雅思":
          if (type == "tableList") {
            this.studentsGradeYasiHandle("list", params);
          } else if (type == "tableSave") {
            this.studentsGradeYasiHandle("update", params);
          } else if (type == "tabelDelete") {
            this.studentsGradeYasiHandle("delete", params);
          } else {
            let formObj = {
              studentAccountId: this.studentId,
              examDate: "",
              grossScore: "",
              readingScore: "",
              hearingScore: "",
              speakingScore: "",
              writingScore: "",
              model: "",
              current: "newAdd" + this.tableData.length
            };
            this.tableData.push(formObj);
          }
          break;
        case "多邻国":
          if (type == "tableList") {
            this.studentsGradeDuolinHandle("list", params);
          } else if (type == "tableSave") {
            this.studentsGradeDuolinHandle("update", params);
          } else if (type == "tabelDelete") {
            this.studentsGradeDuolinHandle("delete", params);
          } else {
            let formObj = {
              studentAccountId: this.studentId,
              examDate: "",
              grossScore: "",
              literacyScore: "",
              comprehensionScore: "",
              conversationScore: "",
              productionScore: "",
              model: "1",
              current: "newAdd" + this.tableData.length
            };
            this.tableData.push(formObj);
          }
          break;
        case "SAT":
          if (type == "tableList") {
            this.studentsGradeSatHandle("list", params);
          } else if (type == "tableSave") {
            this.studentsGradeSatHandle("update", params);
          } else if (type == "tabelDelete") {
            this.studentsGradeSatHandle("delete", params);
          } else {
            let formObj = {
              studentAccountId: this.studentId,
              examDate: "",
              grossScore: "",
              readingGrammarScore: "",
              mathScore: "",
              readingScore: "",
              analysisScore: "",
              writingScore: "",
              model: "",
              current: "newAdd" + this.tableData.length
            };
            this.tableData.push(formObj);
          }
          break;
        case "SAT2":
          if (type == "tableList") {
            this.studentsGradeSat2Handle("list", params);
          } else if (type == "tableSave") {
            // console.log("SAT2保存1", params)
            let paramsObj = JSON.parse(JSON.stringify(params));
            let paramsKeys = Object.keys(paramsObj);
            let filterKeys = paramsKeys.filter(
              item => item.includes("subjectScore") && item != "subjectScores"
            );
            let filterIds = filterKeys.map(item =>
              item.replace("subjectScore", "")
            );
            let subjectArr = JSON.parse(JSON.stringify(this.sat2SubjectData));
            if (subjectArr.length > 0) {
              subjectArr.map(itemOne => {
                filterIds.map(itemTwo => {
                  if (itemTwo == itemOne.standardSat2ExtendId) {
                    itemOne.subjectScore = paramsObj[`subjectScore${itemTwo}`];
                    return itemOne;
                  }
                });
              });
            }
            // console.log("subjectArr", subjectArr)
            paramsObj.subjectScores = subjectArr;
            // console.log("SAT2保存2", paramsObj)
            this.studentsGradeSat2Handle("update", paramsObj);
          } else if (type == "tabelDelete") {
            this.studentsGradeSat2Handle("delete", params);
          } else {
            let formObj = {
              studentAccountId: this.studentId,
              examDate: "",
              model: "",
              current: "newAdd" + this.tableData.length
            };
            if (this.sat2SubjectData.length > 0) {
              this.sat2SubjectData.forEach(itemOne => {
                this.$set(
                  formObj,
                  `subjectScore${itemOne.standardSat2ExtendId}`,
                  ""
                );
              });
            }
            this.tableData.push(formObj);
          }
          break;
        case "AP":
          if (type == "tableList") {
            this.studentsGradeApHandle("list", params);
          } else if (type == "tableSave") {
            this.studentsGradeApHandle("update", params);
          } else if (type == "tabelDelete") {
            this.studentsGradeApHandle("delete", params);
          } else {
            let formObj = {
              studentAccountId: this.studentId,
              examDate: "",
              subjectName: "",
              subjectScore: "",
              model: "",
              current: "newAdd" + this.tableData.length
            };
            this.tableData.push(formObj);
          }
          break;
        case "ALevel":
          if (type == "tableList") {
            this.studentsGradeALevelHandle("list", params);
          } else if (type == "tableSave") {
            // console.log("SAT2保存1", params)
            let paramsObj = JSON.parse(JSON.stringify(params));
            let paramsKeys = Object.keys(paramsObj);
            let filterKeys = paramsKeys.filter(
              item => item.includes("subjectScore") && item != "subjectScores"
            );
            let filterIds = filterKeys.map(item =>
              item.replace("subjectScore", "")
            );
            let subjectArr = JSON.parse(JSON.stringify(this.aLevelSubjectData));
            if (subjectArr.length > 0) {
              subjectArr.map(itemOne => {
                filterIds.map(itemTwo => {
                  if (itemTwo == itemOne.standardAlevelExtendId) {
                    itemOne.subjectScore = paramsObj[`subjectScore${itemTwo}`];
                    return itemOne;
                  }
                });
              });
            }
            // console.log("subjectArr", subjectArr)
            paramsObj.subjectScores = subjectArr;
            // console.log("SAT2保存2", paramsObj)
            this.studentsGradeALevelHandle("update", paramsObj);
          } else if (type == "tabelDelete") {
            this.studentsGradeALevelHandle("delete", params);
          } else {
            let formObj = {
              studentAccountId: this.studentId,
              examDate: "",
              type: "",
              current: "newAdd" + this.tableData.length
            };
            if (this.aLevelSubjectData.length > 0) {
              this.aLevelSubjectData.forEach(itemOne => {
                this.$set(
                  formObj,
                  `subjectScore${itemOne.standardALevelExtendId}`,
                  ""
                );
              });
            }
            this.tableData.push(formObj);
          }
          break;
        case "IB":
          if (type == "tableList") {
            this.studentsGradeIbHandle("list", params);
          } else if (type == "tableSave") {
            let paramsObj = JSON.parse(JSON.stringify(params));
            console.log("IB数据保存1", paramsObj);
            let newParams = {
              standardIbId: "",
              paramsArr: []
            };
            if (paramsObj.standardIbId) {
              newParams.standardIbId = paramsObj.standardIbId;
            }
            let newArr1 = [];
            let newArr2 = [];
            let newArr3 = [];
            paramsObj.subjectName.forEach((itemOne, indexOne) => {
              if (itemOne.type == "1") {
                newArr1.push({
                  studentAccountId: this.studentId,
                  examDate: paramsObj.examDate,
                  type: "1",
                  subjectName: itemOne.value,
                  grossScore: paramsObj.grossScore.filter(
                    itemTwo => itemTwo.type == "1"
                  )[0].value,
                  tokEe: paramsObj.tokEe.filter(
                    itemTwo => itemTwo.type == "1"
                  )[0].value,
                  level: paramsObj.level.filter(itemTwo => itemTwo.type == "1")[
                    indexOne
                  ].value,
                  subjectScore: paramsObj.subjectScore.filter(
                    itemTwo => itemTwo.type == "1"
                  )[indexOne].value,
                  standardIbId: itemOne.standardIbId ? itemOne.standardIbId : ""
                });
              } else {
                newArr2.push({
                  studentAccountId: this.studentId,
                  examDate: paramsObj.examDate,
                  type: "2",
                  subjectName: itemOne.value,
                  grossScore: paramsObj.grossScore.filter(
                    itemTwo => itemTwo.type == "2"
                  )[0].value,
                  tokEe: paramsObj.tokEe.filter(
                    itemTwo => itemTwo.type == "2"
                  )[0].value,
                  level: paramsObj.level.filter(itemTwo => itemTwo.type == "2")[
                    indexOne - 6
                  ].value,
                  subjectScore: paramsObj.subjectScore.filter(
                    itemTwo => itemTwo.type == "2"
                  )[indexOne - 6].value,
                  standardIbId: itemOne.standardIbId ? itemOne.standardIbId : ""
                });
              }
            });
            console.log("IB数据保存2", newArr1, newArr2);
            newArr3 = [...newArr1, ...newArr2];
            newParams.paramsArr = newArr3;
            console.log("IB数据保存3", newParams);
            this.studentsGradeIbHandle("update", newParams);
          } else if (type == "tabelDelete") {
            this.studentsGradeIbHandle("delete", params);
          } else {
            let formObj = {
              examDate: "",
              type: ["1", "2"],
              grossScore: [
                {
                  type: "1",
                  value: ""
                },
                {
                  type: "2",
                  value: ""
                }
              ],
              tokEe: [
                {
                  type: "1",
                  value: ""
                },
                {
                  type: "2",
                  value: ""
                }
              ],
              subjectName: [
                {
                  type: "1",
                  value: ""
                },
                {
                  type: "1",
                  value: ""
                },
                {
                  type: "1",
                  value: ""
                },
                {
                  type: "1",
                  value: ""
                },
                {
                  type: "1",
                  value: ""
                },
                {
                  type: "1",
                  value: ""
                },
                {
                  type: "2",
                  value: ""
                },
                {
                  type: "2",
                  value: ""
                },
                {
                  type: "2",
                  value: ""
                },
                {
                  type: "2",
                  value: ""
                },
                {
                  type: "2",
                  value: ""
                },
                {
                  type: "2",
                  value: ""
                }
              ],
              level: [
                {
                  type: "1",
                  value: ""
                },
                {
                  type: "1",
                  value: ""
                },
                {
                  type: "1",
                  value: ""
                },
                {
                  type: "1",
                  value: ""
                },
                {
                  type: "1",
                  value: ""
                },
                {
                  type: "1",
                  value: ""
                },
                {
                  type: "2",
                  value: ""
                },
                {
                  type: "2",
                  value: ""
                },
                {
                  type: "2",
                  value: ""
                },
                {
                  type: "2",
                  value: ""
                },
                {
                  type: "2",
                  value: ""
                },
                {
                  type: "2",
                  value: ""
                }
              ],
              subjectScore: [
                {
                  type: "1",
                  value: ""
                },
                {
                  type: "1",
                  value: ""
                },
                {
                  type: "1",
                  value: ""
                },
                {
                  type: "1",
                  value: ""
                },
                {
                  type: "1",
                  value: ""
                },
                {
                  type: "1",
                  value: ""
                },
                {
                  type: "2",
                  value: ""
                },
                {
                  type: "2",
                  value: ""
                },
                {
                  type: "2",
                  value: ""
                },
                {
                  type: "2",
                  value: ""
                },
                {
                  type: "2",
                  value: ""
                },
                {
                  type: "2",
                  value: ""
                }
              ],
              current: "newAdd" + this.tableData.length
            };
            this.tableData.push(formObj);
          }
          break;
        case "GRE":
          if (type == "tableList") {
            this.studentsGradeGreHandle("list", params);
          } else if (type == "tableSave") {
            this.studentsGradeGreHandle("update", params);
          } else if (type == "tabelDelete") {
            this.studentsGradeGreHandle("delete", params);
          } else {
            let formObj = {
              studentAccountId: this.studentId,
              examDate: "",
              model: "",
              grossScore: "",
              verbal: "",
              verbalPercent: "",
              quantitative: "",
              quantitativePercent: "",
              analytical: "",
              analyticalPercent: "",
              writingScore: "",
              current: "newAdd" + this.tableData.length
            };
            this.tableData.push(formObj);
          }
          break;
        case "GMAT":
          if (type == "tableList") {
            this.studentsGradeGmatHandle("list", params);
          } else if (type == "tableSave") {
            this.studentsGradeGmatHandle("update", params);
          } else if (type == "tabelDelete") {
            this.studentsGradeGmatHandle("delete", params);
          } else {
            let formObj = {
              studentAccountId: this.studentId,
              examDate: "",
              model: "",
              grossScore: "",
              quantitative: "",
              verbal: "",
              analytical: "",
              integrated: "",
              current: "newAdd" + this.tableData.length
            };
            this.tableData.push(formObj);
          }
          break;
      }
    },
    handleForm(type, scope) {
      switch (type) {
        case "gpa":
          this.gpaDetail = {};
          this.$nextTick(() => {
            this.$refs.gpaDetail.clearValidate();
          });
          this.diaLogGpaTitle = "GPA数值添加";
          this.diaLogGpaVisible = true;
          break;
        case "table":
          this.differentTabFn(this.currentTab, "tableAdd");
          break;
        case "subject":
          this.subjectDetail = {};
          this.$nextTick(() => {
            this.$refs.subjectDetail.clearValidate();
          });
          this.diaLogSubjectTitle = "科目添加";
          this.diaLogSubjectVisible = true;
          break;
        case "delete":
          let mapObj = {
            托福: "studentStandardToeflId",
            雅思: "studentStandardIeltsId",
            多邻国: "studentStandardDuolingoId",
            SAT: "studentStandardSatId",
            SAT2: "studentStandardSat2Id",
            AP: "studentStandardApId",
            ALevel: "studentStandardAlevelId",
            IB: "recordNumber",
            GRE: "studentStandardGreId",
            GMAT: "studentStandardGmatId"
          };
          let mapKey = mapObj[this.currentTab] + "";
          let mapVal = mapKey.replace("studentS", "s");
          if (scope[mapVal]) {
            this.deleteObj = {
              [mapKey]: scope[mapVal]
            };
            this.messegeVisible = true;
            this.messegeTip = "信息提示";
            this.messegeContent = "确定删除当前选中标化成绩信息吗？";
            this.messegeType = "warn";
          } else {
            console.log("列表删除", scope, scope[mapVal]);
            let deleteCurrent = this.tableData.findIndex(
              item => item.current == scope.current
            );
            if (deleteCurrent != -1) {
              this.tableData.splice(deleteCurrent, 1);
            }
          }
          break;
        case "save":
          this.differentTabFn(this.currentTab, "tableSave", scope);
          break;
        case "submitGpa":
          this.$refs["gpaDetail"].validate(valid => {
            if (valid) {
              let gpaData = JSON.parse(JSON.stringify(this.gpaDetail));
              gpaData.studentAccountId = this.studentId;
              AddStudentGradeGpa(gpaData).then(res => {
                if (res.code == 0) {
                  this.$message({
                    message: "GPA添加成功~",
                    type: "success"
                  });
                  this.diaLogGpaVisible = false;
                  this.studentsGradeGpa();
                }
              });
            }
          });
          break;
        case "submitSubject":
          this.$refs["subjectDetail"].validate(valid => {
            if (valid) {
              let subjectData = JSON.parse(JSON.stringify(this.subjectDetail));
              subjectData.studentAccountId = this.studentId;
              if (this.currentTab == "SAT2") {
                AddStudentGradeSat2Subject(subjectData).then(async res => {
                  if (res.code == 0) {
                    this.$message({
                      message: "科目添加成功~",
                      type: "success"
                    });
                    this.diaLogSubjectVisible = false;
                    await this.studentsGradeSat2Subject();
                    // if (this.tableData.length > 0) {
                    // 	this.tableData.forEach(item => {
                    // 		item.subjectScores = this.sat2SubjectData
                    // 	})
                    // }
                  }
                });
              } else if (this.currentTab == "ALevel") {
                AddStudentGradeALevelSubject(subjectData).then(async res => {
                  if (res.code == 0) {
                    this.$message({
                      message: "科目添加成功~",
                      type: "success"
                    });
                    this.diaLogSubjectVisible = false;
                    await this.studentsGradeALevelSubject();
                  }
                });
              }
            }
          });
          break;
      }
    },
    // 数据去重
    reduceArr(intArr) {
      return new Promise((resolve, reject) => {
        let emptyArr = [];
        let emptyObj = {};
        emptyArr = intArr.reduce((item, next) => {
          // console.log("reduceArr>>>>>", next)
          emptyObj[next.type]
            ? ""
            : (emptyObj[next.type] = true && item.push(next));
          return item;
        }, []);
        // console.log("reduceArr>>>>>", emptyArr)
        resolve(emptyArr);
      });
    },
    modelText(val) {
      let mapObj = {
        "1": "线上",
        "2": "线下"
      };
      return mapObj[val];
    },
    typeText(val) {
      let mapObj = {
        "1": "IGCES",
        "2": "As Level",
        "3": "A Level"
      };
      return mapObj[val];
    },
    changeTab(val) {
      console.log("changeTab", val.label);
      this.currentTab = val.label;
      this.studentsGradeView();
    },
    closeGpaDialog() {
      this.diaLogGpaVisible = false;
    },
    closeSubjectDialog() {
      this.diaLogSubjectVisible = false;
    },
    closeMessege() {
      this.messegeVisible = false;
    },
    confirmMessege() {
      this.differentTabFn(this.currentTab, "tabelDelete", this.deleteObj);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../styles/index.scss";

input::-webkit-input-placeholder {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 20px;
  color: #999999 !important;
}

/deep/.gradeTable_table input:focus {
  outline: none;
  border: 1px solid $theme_color !important;
}

/deep/.el-tabs__item {
  width: 120px;
  text-align: center;
  padding: 0;
  font-weight: 300;

  &.is-active {
    font-weight: bold !important;
  }
}

/deep/.gradeTable_table_select .el-input__inner {
  height: 60px;
}

/deep/.handle_form_date .el-input__inner {
  height: 48px;
}

/deep/.handle_form_date .el-input__inner:focus {
  border: 1px solid #eeeeee;
}

/deep/.handle_form_date .el-input__inner:hover {
  border: 1px solid #eeeeee;
}

/deep/.handle_form_date .el-input__inner {
  border: 1px solid #eeeeee;
}

.flex_item {
  display: flex;
  align-items: center;
}

.border_left {
  border-left: 1px solid #eeeeee !important;
}

.handle_form {
  .handle_form_row {
    margin-top: 25px;
    // height: 48px;
    display: flex;
    // padding: 0 23px;

    .handle_form_item {
      flex: 1;
      display: flex;

      .handle_form_ipt {
        width: 423px;
        border: 1px solid #eeeeee;
        outline: none;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 46px;
        color: #000000;
        padding: 0 10px;
        box-sizing: border-box;
      }

      .handle_form_date {
        width: 423px;
        outline: none;
      }
    }
  }

  .handle_form_btn {
    display: flex;
    align-items: center;
    margin: 40px 0 0px 0;

    .handle_form_cancel {
      margin-left: auto;
      width: 76px;
      line-height: 34px;
      background: #ffffff;
      border: 1px solid $theme_color;
      border-radius: 4px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: $theme_color;
      text-align: center;
      cursor: pointer;
      margin-right: 16px;
    }

    .handle_form_confirm {
      width: 76px;
      line-height: 34px;
      background: $theme_color;
      border-radius: 4px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #fff;
      text-align: center;
      cursor: pointer;
    }
  }
}

.gradeTable {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;

  .gradeTable_gpa {
    display: flex;
    align-items: center;
    margin: 22px 0;

    .gradeTable_gpa_label {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 20px;
      color: $theme_color;
      margin-right: 10px;
    }

    .gradeTable_gpa_list {
      flex: 1;
      display: flex;
      align-items: center;

      .gradeTable_gpa_item {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 26px;
        color: $theme_color;
        background: rgba(91, 168, 151, 0.1);
        border-radius: 2px;
        padding: 0 8px;
        margin-right: 10px;
      }

      .gradeTable_gpa_btn {
        width: 113px;
        border: 1px solid $theme_color;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 26px;
        color: $theme_color;
        box-sizing: border-box;
        cursor: pointer;

        img {
          width: 11px;
          height: 11px;
          margin-right: 8px;
        }
      }
    }
  }

  .gradeTable_tab {
  }

  .gradeTable_handle_btn {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .gradeTable_add_btn {
      width: 107px;
      line-height: 32px;
      border: 1px solid $theme_color;
      border-radius: 2px;
      text-align: center;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: $theme_color;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      img {
        width: 11px;
        height: 11px;
        margin-right: 8px;
      }
    }

    .gradeTable_save_btn {
      width: 76px;
      line-height: 32px;
      background: $theme_color;
      border: 1px solid $theme_color;
      border-radius: 4px;
      text-align: center;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      margin-left: 24px;
      cursor: pointer;
    }
  }

  .gradeTable_table {
    flex: 1;
    display: flex;
    flex-direction: column;

    .gradeTable_table_box {
      flex: 1;
      // position: relative;

      .gradeTable_table_inner {
        // position: absolute;
        width: 100%;
        display: flex;
        flex-direction: column;

        .border_bottom {
          height: 60px;
          display: flex;
          align-items: center;
          border-bottom: 1px solid #ebeef5;
        }

        .gradeTable_table_value {
          height: 100%;

          .table_value_h360 {
            line-height: 360px;

            .gradeTable_table_ipt360 {
              width: 100%;
              outline: none;
              box-sizing: border-box;
              height: 360px;
              border: 0;
              text-align: center;
              padding: 0 10px;
            }
          }

          .table_value_h60 {
            height: 60px;

            .gradeTable_table_ipt60 {
              width: 100%;
              outline: none;
              box-sizing: border-box;
              height: 59px;
              border: 0;
              text-align: center;
              padding: 0 10px;
            }
          }
        }

        .gradeTable_table_value :first-child.table_value_h360 {
          border-bottom: 1px solid #ebeef5;
        }

        .gradeTable_table_date {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;

          .table_date_icon {
            margin-right: 10px;
          }
        }

        .gradeTable_table_date .el-date-editor {
          width: 100%;
          position: absolute; //绝对定位
          top: 0;
          left: 10px;
          opacity: 0; //设置完全透明
        }

        .gradeTable_table_ipt {
          width: 100%;
          outline: none;
          box-sizing: border-box;
          height: 60px;
          border: 0;
          text-align: center;
          padding: 0 10px;
        }

        .gradeTable_table_select {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;

          .table_select_inner {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 60px;

            span {
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              line-height: 20px;
              color: #333333;
              margin-right: 8px;
            }

            img {
              width: 16px;
              height: 16px;
            }
          }
        }

        .gradeTable_table_action {
          width: 100%;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 0px;
            color: $theme_color;
            cursor: pointer;
            margin: 0 8px;
          }
        }

        .gradeTable_table_select .el-select {
          position: absolute; //绝对定位
          top: 0;
          // left: 0px;
          opacity: 0; //设置完全透明
        }
      }
    }
  }
}
</style>
