<template>
	<div class="familyTable">
		<div class="familyTable_box" v-for="(item, index) in familyViews" :key="index">
			<div class="" v-if="item.relationType != 3">
				<div class="familyTable_title">
					<span
						class="familyTable_title_left">{{item.relationType == 1?'父亲信息': item.relationType == 2?'母亲信息':''}}</span>
					<span class="familyTable_title_right">
						<span @click="handleFn('save')" v-if="item.relationType == 1&&showSaveBtn" class="familyTable_btn">保存</span>
					</span>
				</div>
				<div class="familyTable_point">
					<span class="familyTable_point_circle"></span>
					<span class="familyTable_point_value">基本信息</span>
				</div>
				<div class="familyTable_content">
					<div class="table_box">
						<div class="table_td">
							<div class="table_tr">
								<div class="table_label">
									{{item.relationType == 1?'父亲姓名': item.relationType == 2?'母亲姓名':''}}
								</div>
								<input v-model="item.name"
									:placeholder="item.relationType == 1?'请输入父亲姓名': item.relationType == 2?'请输入母亲姓名':''"
									class="table_value clear_border_right"></input>
							</div>
							<div class="table_tr">
								<div class="table_label">
									出生日期
								</div>
								<div class="table_value">
									<span class="personalTable_date"><i
											class="personalTable_date_icon el-icon-date"></i>{{item.birthday?item.birthday: '请选择'}}</span>
									<el-date-picker @change="dateSelect($event, 'birthday', item)"
										v-model="item.birthday" type="date" placeholder="选择日期">
									</el-date-picker>
								</div>
							</div>
						</div>
						<div class="table_td">
							<div class="table_tr">
								<div class="table_label">
									联系电话
								</div>
								<input v-model="item.tel" placeholder="请输入联系电话"
									class="table_value clear_border_right"></input>
							</div>
							<div class="table_tr">
								<div class="table_label">
									邮箱
								</div>
								<input v-model="item.email" placeholder="请输入邮箱" class="table_value"></input>
							</div>
						</div>
						<div class="table_td">
							<div class="table_tr">
								<div class="table_label">
									是否健在
								</div>
								<div class="table_value clear_border_right">
									<radioGroup :radioIndex="index" :currentRadio="item.alive+''"
										@checkRadio="checkAlive" :radioList="healthList" />
								</div>
							</div>
							<div class="table_tr">
								<div class="table_label">
									婚姻状态
								</div>
								<div class="table_value">
									<radioGroup :radioIndex="index" :currentRadio="item.married+''"
										@checkRadio="checkMarried" :radioList="merryList" />
								</div>
							</div>
						</div>
						<div class="table_td">
							<div class="table_tr">
								<div class="table_label">
									工作单位
								</div>
								<input v-model="item.workUnit" placeholder="请输入工作单位"
									class="table_value clear_border_right"></input>
							</div>
							<div class="table_tr">
								<div class="table_label">
									职位
								</div>
								<input v-model="item.jobTitle" placeholder="请输入职位" class="table_value"></input>
							</div>
						</div>
						<div class="table_td">
							<div class="table_tr">
								<div class="table_label">
									工作所在地址
								</div>
								<input v-model="item.workAddr" placeholder="请输入工作所在地址" class="table_value"></input>
							</div>
						</div>
						<div class="table_td">
							<div class="table_tr">
								<div class="table_label border_bottom">
									家庭住址
								</div>
								<input v-model="item.homeAddr" placeholder="请输入家庭住址"
									class="table_value border_bottom"></input>
							</div>
						</div>
					</div>
				</div>
				<div class="familyTable_point">
					<span class="familyTable_point_circle"></span>
					<span class="familyTable_point_value">学历信息</span>
				</div>
				<div class="familyTable_content">
					<div class="table_box">
						<div class="table_td">
							<div class="table_tr">
								<div class="table_label">
									毕业院校
								</div>
								<input v-model="item.graduateSchool" placeholder="请输入毕业院校"
									class="table_value clear_border_right"></input>
							</div>
							<div class="table_tr">
								<div class="table_label">
									毕业时间
								</div>
								<div class="table_value">
									<span class="personalTable_date"><i
											class="personalTable_date_icon el-icon-date"></i>{{item.graduateDate?item.graduateDate: '请选择'}}</span>
									<el-date-picker @change="dateSelect($event, 'graduateDate', item)"
										v-model="item.graduateDate" type="date" placeholder="选择日期">
									</el-date-picker>
								</div>
							</div>
						</div>
						<div class="table_td">
							<div class="table_tr height_87">
								<div class="table_label height_87">
									学位<br />(如有多个学位，请分别提供每个学位对应的院校和毕业时间)
								</div>
								<input v-model="item.degrees" placeholder="请输入家庭住址"
									class="table_value height_87"></input>
							</div>
						</div>
						<div class="table_td">
							<div class="table_tr">
								<div class="table_label">
									就读时间
								</div>
								<div class="table_value border_bottom clear_border_right">
									<span class="personalTable_date"><i
											class="personalTable_date_icon el-icon-date"></i>{{item.studyTime?item.studyTime: '请选择'}}</span>
									<el-date-picker @change="dateSelect($event, 'studyTime', item)"
										v-model="item.studyTime" type="date" placeholder="选择日期">
									</el-date-picker>
								</div>
							</div>
							<div class="table_tr">
								<div class="table_label">
									所在城市
								</div>
								<input v-model="item.city" placeholder="请输入所在城市"
									class="table_value border_bottom"></input>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-else class="">
				<div class="flex-start">
					<div class="familyTable_title">
						<span class="familyTable_title_left">其他家庭成员信息（直系亲属，如兄弟、姐妹等）</span>
						<span class="familyTable_title_right">
							<div class="flex-start mt" @click="handleFn('delete',item,index)" v-if="index>2">
								<img class="delete-icon" src="@/assets/images/common/delete-icon.png" alt="">
								<span class="delete-text">删除任务</span>
							</div>
						</span>
					</div>
				</div>
				<div class="familyTable_content">
					<div class="table_box">
						<div class="table_td">
							<div class="table_tr">
								<div class="table_label">
									姓名
								</div>
								<input v-model="item.name" placeholder="请输入姓名"
									class="table_value clear_border_right"></input>
							</div>
							<div class="table_tr">
								<div class="table_label">
									出生日期
								</div>
								<div class="table_value border_bottom">
									<span class="personalTable_date"><i
											class="personalTable_date_icon el-icon-date"></i>{{item.birthday?item.birthday:'请选择'}}</span>
									<el-date-picker @change="dateSelect($event, 'birthday', item)"
										v-model="item.birthday" type="date" placeholder="选择日期">
									</el-date-picker>
								</div>
							</div>
						</div>
						<div class="table_td">
							<div class="table_tr">
								<div class="table_label border_bottom">
									与学生关系
								</div>
								<input v-model="item.relation" placeholder="请输入与学生关系"
									class="table_value border_bottom"></input>
							</div>
							<div class="table_tr">
							</div>
						</div>
					</div>
				</div>
				<!-- <div @click="handleFn('delete',item,index)" v-if="index>2">删除{{index}}</div> -->
			</div>
		</div>
		<div @click="handleFn('add')" class="familyTable_add">
			<img src="@/assets/images/studentInformation/add.png">
		</div>
		<messege-box ref="messegeBox" @confirmMessege="confirmMessege" @closeMessege="closeMessege"
			:isOpen="messegeVisible" :messegeTip="messegeTip" :messegeContent="messegeContent"
			:messegeType="messegeType" />
	</div>
</template>

<script>
	import {
		familyViewStudents,
		AddStudentFamily,
		DeleteStudentFamily
	} from "@/api/common"
	import radioGroup from "@/components/radioGroup/index.vue";
	import commonFn from "@/utils/common.js";
	export default {
		components: {
			radioGroup
		},
		data() {
			return {
				healthList: [{
						label: "1",
						value: "是",
					},
					{
						label: "2",
						value: "否",
					}
				],
				merryList: [{
						label: "1",
						value: "已婚",
					},
					{
						label: "2",
						value: "离异",
					}
				],
				messegeVisible: false,
				messegeTip: "",
				messegeContent: "",
				messegeType: "",
				familyIndex: null,
				familyViews: [],
				deleteObj:{},
				index:-1,
				showSaveBtn: false,
				familyViewCopy: [],
			}
		},
		props: {
			studentId: {
				type: String,
				default: function() {
					return ""
				}
			}
		},
		watch: {
			familyViews: {
				handler(val) {
					console.log("familyViews-watch", val, this.familyViewCopy)
					let origin = JSON.stringify(val)
					let copy = JSON.stringify(this.familyViewCopy)
					if(origin != copy) {
						this.showSaveBtn = true
					} else {
						this.showSaveBtn = false
					}
				},
				immediate: true,
				deep: true
			}
		},
		methods: {
			closeMessege() {
				this.messegeVisible = false
			},
			confirmMessege() {
				if(!this.deleteObj.studentFamilyId){
					this.familyViews.splice(this.index,1)
					this.messegeVisible = false
					this.familyViewCopy = JSON.parse(JSON.stringify(this.familyViews))
				}else{
					this.messegeVisible = false
					this.differentTabFn(this.deleteObj)
				}
				// this.differentTabFn('tabelDelete', this.deleteObj)
			},
			differentTabFn(deleteObj){
				DeleteStudentFamily(deleteObj).then(res=>{
					if(res.code ==0 ){
						this.messegeVisible = false
						this.showSaveBtn = false
						this.$message({
							message: "学生家庭资料删除成功~",
							type: 'success'
						})
						this.studenFamilyView()
					}
				})
			},
			// 时间选择
			dateSelect(value, key, item) {
				console.log("dateSelect", value, item)
				item[key] = commonFn.timeFormat(value, "yyyy-MM-dd")
				this.$forceUpdate()
			},
			// 学生家庭资料
			studenFamilyView() {
				let params = {
					studentInfoId: this.studentId
				}
				this.familyViews = []
				familyViewStudents(params).then(res => {
					if (res.code == 0) {
						if (res.data.length > 0) {
							this.familyViews = res.data
						} else {
							this.familyViews.push({
								studentAccountId: this.studentId,
								name: "",
								relationType: "1",
								birthday: "",
								tel: "",
								email: "",
								alive: '1',
								married: "1",
								workUnit: "",
								jobTitle: "",
								workAddr: "",
								homeAddr: "",
								graduateSchool: "",
								graduateDate: "",
								degrees: "",
								studyTime: "",
								city: ""
							}, {
								studentAccountId: this.studentId,
								name: "",
								relationType: "2",
								birthday: "",
								tel: "",
								email: "",
								alive: '1',
								married: "1",
								workUnit: "",
								jobTitle: "",
								workAddr: "",
								homeAddr: "",
								graduateSchool: "",
								graduateDate: "",
								degrees: "",
								studyTime: "",
								city: ""
							}, {
								studentAccountId: this.studentId,
								name: "",
								relationType: "3",
								birthday: "",
								relation: ""
							})
						}
					    this.familyViewCopy = JSON.parse(JSON.stringify(this.familyViews))
					}
				})
			},
			handleFn(type,item,index) {
				switch (type) {
					case "save":
						// 保存家庭资料
						AddStudentFamily(this.familyViews).then((res) => {
							if (res.code == 0) {
								this.$message({
									message: "家庭资料编辑成功~",
									type: 'success'
								})
								this.showSaveBtn = false
								this.studenFamilyView()
							}
						})
						break;
					case "add":
						this.familyViews.push({
							studentAccountId: this.studentId,
							name: "",
							relationType: "3",
							birthday: "",
							relation: ""
						})
						this.familyViewCopy = JSON.parse(JSON.stringify(this.familyViews))
						break;
					case "delete":
						this.index = index
						if(item.studentFamilyId){
							this.deleteObj = {
								studentFamilyId: item.studentFamilyId
							}
						}else{
							this.deleteObj = {}
						}
						this.messegeVisible = true
						this.messegeTip = "信息提示"
						this.messegeContent = "确定删除当前选中家庭资料吗？"
						this.messegeType = "warn"
						// }
						break;
				}
			},
			checkAlive(props) {
				console.log("checkAlive", props)
				this.familyViews[props.currentIndex].alive = props.label
			},
			checkMarried(props) {
				console.log("checkMarried", props)
				this.familyViews[props.currentIndex].married = props.label
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import "../../../styles/index.scss";
	@import "./common.scss";

	.height_87 {
		height: 87px !important;
	}


	.familyTable {
		display: flex;
		flex-direction: column;

		.familyTable_box {
			display: flex;
			flex-direction: column;

			.familyTable_title {
				display: flex;
				line-height: 24px;
				margin-top: 20px;

				.familyTable_title_left {
					font-size: 16px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #333333;
					box-sizing: border-box;
					border-bottom: 2px solid $theme_color;
				}

				.familyTable_title_right {
					flex: 1;
					border-bottom: 1px solid #EEEEEE;
					display: flex;
					.mt{
						margin-top:24px;
						cursor: pointer;
						margin-left:auto;
						.delete-icon{
							width:14px;
							height:14px;
							margin-right:8px;
						}
						.delete-text{
							font-size:14px;
						}
					}
					
					.familyTable_btn {
						width: 80px;
						background: $theme_color;
						border-radius: 4px;
						margin-left: auto;
						font-size: 14px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						line-height: 34px;
						color: #FFFFFF;
						text-align: center;
						cursor: pointer;
					}
				}
			}

			.familyTable_point {
				display: flex;
				align-items: center;
				margin: 10px 0 0 0;

				.familyTable_point_circle {
					width: 6px;
					height: 6px;
					background: #333333;
					border-radius: 3px;
					margin-right: 12px;
				}

				.familyTable_point_value {
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 20px;
					color: #333333;
				}
			}

			.familyTable_content {
				flex: 1;
				margin-top: 10px;
			}
			
		}

		.familyTable_add {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 88px;
			border: 1px solid #EEEEEE;
			cursor: pointer;
			margin-top: 10px;

			img {
				width: 50px;
				height: 50px;
			}
		}
	}
</style>
