<template>
	<div class="fileLibrary">
		<div @click="handleForm('add')" class="fileLibrary_btn">
			+添加文件
		</div>
		<div v-if="fileLibraryList.length > 0" class="fileLibrary_list">
			<div v-for="(item,index) in fileLibraryList" :key="index" class="fileLibrary_item">
				<div class="fileLibrary-box">
					<div class="fileLibrary_item_head">
						<span class="fileLibrary_item_circle"></span>
						<span class="fileLibrary_item_title">{{item.title}}</span>
						<span class="fileLibrary_item_time">上传时间：{{item.createTime}}</span>
						<span class="fileLibrary_item_time">上传人：{{item.createUserName}}</span>
					</div>
					<div class="flex-start mt" @click="handleFn(item)">
						<img class="delete-icon" src="@/assets/images/common/delete-icon.png" alt="">
						<span class="delete-text">删除任务</span>
					</div>
				</div>
				
				<div class="fileLibrary_item_content">
					<div class="fileLibrary_item_file" v-if="item.attPath">
						<el-image 
							v-if="item.type == 'image'"
							class="fileLibrary_item_cover"
							:src="item.attPath" 
							:preview-src-list="item.attPathArr">
						</el-image>
						<!-- <img v-if="item.type == 'image'" :src="item.attPath" class="fileLibrary_item_cover img-cover"> -->
						<img v-if="item.type == 'text'" src="@/assets/images/dashboard/text.png"
							class="fileLibrary_item_cover">
						<img v-if="item.type == 'video'" src="@/assets/images/dashboard/video.png"
							class="fileLibrary_item_cover">
						<span class="fileLibrary_item_btn" @click="handleUpload(item)">下载</span>
					</div>
					<div class="fileLibrary_item_desc">
						<span class="fileLibrary_item_label">备注</span>
						<div class="fileLibrary_item_textarea">{{item.remark}}</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="fileLibrary_empty">
			<img src="@/assets/images/common/empty1.png">
			<span>暂无文件</span>
		</div>
		<div class="fileLibrary_dialog">
			<el-dialog :modal="false" class="dialog_bg" :append-to-body="false" :modal-append-to-body="false" @close="closefileDialog" width="579px" :title="diaLogfileTitle"
				:visible.sync="diaLogfileVisible">
				<div class="handle_form">
					<el-form ref="fileDetail" :rules="fileRules" :model="fileDetail" label-width="100px">
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="flex_item" label="文件标题:" prop="title">
									<input placeholder="请输入文件标题" class="handle_form_ipt" v-model="fileDetail.title"></input>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="" label="上传附件:" prop="attPath" ref="uploadFiles">
									<uploadFile  @successUpload="successUpload" :fileSrc="fileDetail.attPath"/>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="" label="备注:">
									<textarea placeholder="请输入备注" class="handle_form_textarea" v-model="fileDetail.remark"></textarea>
								</el-form-item>
							</div>
						</div>
					</el-form>
					<div class="handle_form_btn">
						<span @click="closefileDialog" class="handle_form_cancel">取消</span>
						<span v-preventReClick @click="submitFile" class="handle_form_confirm">确定</span>
					</div>
				</div>
			</el-dialog>
		</div>
		<messege-box ref="messegeBox" @confirmMessege="confirmMessege" @closeMessege="closeMessege"
			:isOpen="messegeVisible" :messegeTip="messegeTip" :messegeContent="messegeContent"
			:messegeType="messegeType" />
	</div>
</template>

<script>
	import {
		listStudentFiles,
		addStudentFiles,
		DeletelistStudentFiles
	} from "@/api/common"
	import uploadFile from "@/components/uploadFile/index.vue";
	import getType from "@/utils/matchFiletype.js"
	import {
		saveAs
	} from 'file-saver'
	export default {
		components: {
			uploadFile
		},
		props: {
			studentId: {
				type: String,
				default: function() {
					return ""
				}
			}
		},
		data() {
			const checkAttpath = (rule, value, callback) => {
				if (!value) {
					callback(new Error("请上文件或者图片"));
				} else {
					callback();
				}
				callback();
			};
			return {
				fileLibraryList: [],
				diaLogfileTitle: "",
				diaLogfileVisible: false,
				fileRules: {
					title: [{
						required: true,
						message: '请输入文件标题',
						trigger: 'blur'
					}],
					attPath: [{
						required: true,
						validator: checkAttpath,
						trigger: 'change'
					}],
				},
				fileDetail: {
					attPath:""
				},
				messegeVisible: false,
				messegeTip: "",
				messegeContent: "",
				messegeType: "",
				deleteObj:{}
			}
		},
		created(){
			// this.getStudentFileLists()
		},
		methods: {
			closeMessege() {
				this.messegeVisible = false
			},
			confirmMessege() {
				DeletelistStudentFiles(this.deleteObj).then(res=>{
					if(res.code ==0 ){
						this.messegeVisible = false
						this.$message({
							message: "学生文件库删除成功~",
							type: 'success'
						})
						this.getStudentFileLists()
					}
				})
				// this.differentTabFn('tabelDelete', this.deleteObj)
			},
			//删除文件库
			handleFn(item){
				this.messegeVisible = true
				this.messegeTip = "信息提示"
				this.messegeContent = "确定删除当前选中文件吗？"
				this.messegeType = "warn"
				this.deleteObj = {
					studentFilesId:item.fileId
				}
			},
			//获取学生文件库列表
			getStudentFileLists(){
				listStudentFiles({studentInfoId:this.studentId}).then(res=>{
					if(res.code == 0){
						if(res.data.length>0&&res.data){
							(res.data).forEach(el=>{
								el.type=getType.matchFileType(el.attPath)
								if(el.type=='image'){
									el.attPathArr = []
									el.attPathArr.push(el.attPath)
								}
							})
						}
						this.fileLibraryList = res.data
						// console.log(this.fileLibraryList,'==>>>>>>>文件库')
					}
				})
			},
			handleForm(type) {
				switch (type) {
					case "add":
						this.diaLogfileTitle = "添加文件";
						this.diaLogfileVisible = true;
						if(this.$refs['fileDetail']!==undefined){
							this.$refs['fileDetail'].resetFields()
							this.fileDetail = {}
						}
						break;
				}
			},
			submitFile(){
				this.$refs['fileDetail'].validate(valid => {
					if (valid) {
						this.fileDetail.studentAccountId = this.studentId
						addStudentFiles(this.fileDetail).then(res=>{
							if(res.code ==0 ){
								this.diaLogfileVisible = false
								this.getStudentFileLists()
							}
						})
					}
				})
				
			},
			//点击下载文档
			handleUpload(item){
				// // let url = item.replace("http", "https")
				// window.open(item);
				let pointIndex = item.attNamq.lastIndexOf('.')
				let fileName = ""
				let fileType = ""
				if (pointIndex != -1) {
					fileName = item.attNamq.substring(0, pointIndex).toLowerCase()
					fileType = item.attNamq.substring(pointIndex + 1, item.attNamq.length).toLowerCase()
					// console.log("fileType", fileType)
				}
				if (fileType == 'txt') {
					let xhr = new XMLHttpRequest();
					xhr.open("get", item.attPath, true);
					xhr.responseType = "blob";
					xhr.onload = function() {
						if (this.status == 200) {
							const reader = new FileReader()
							reader.onload = function() {
								// console.log('reader.result', reader.result)
								var blob = new Blob([reader.result], {
									type: 'text/plain;charset=utf-8'
								})
								saveAs(blob, fileName + '.txt')
							}
							reader.readAsText(this.response);
						}
					};
					xhr.send();
				} else {
					window.open(item.attPath);
				}
			},
			successUpload(data){
				this.fileDetail.attPath = data.fileData
				this.fileDetail.attNamq = data.fileName
				this.$refs.uploadFiles.clearValidate()
			},
			closefileDialog() {
				this.diaLogfileVisible = false;
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import "../../../styles/index.scss";

	input::-webkit-input-placeholder {
		font-size: 14px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		line-height: 20px;
		color: #999999 !important;
	}

	textarea::-webkit-input-placeholder {
		font-size: 14px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		color: #999999 !important;
	}

	/deep/input:focus {
		outline: none;
		border: 1px solid $theme_color !important;
	}

	/deep/textarea:focus {
		outline: none;
		border: 1px solid $theme_color !important;
	}

	.flex_item {
		display: flex;
		align-items: center;
	}

	.fileLibrary {
		height: 100%;
		display: flex;
		flex-direction: column;

		.fileLibrary_btn {
			width: 120px;
			line-height: 36px;
			border: 1px solid $theme_color;
			border-radius: 4px;
			text-align: center;
			font-size: 14px;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: $theme_color;
			cursor: pointer;
			margin-bottom: 9px;
		}

		.fileLibrary_list {
			// flex: 1;
			display: flex;
			flex-wrap: wrap;
			margin-left: 8px;

			.fileLibrary_item {
				margin: 10px 4px;
				width: 780px;
				height: 239px;
				background: #FFFFFF;
				box-shadow: 0px 2px 4px rgba(51, 51, 51, 0.16);
				border-radius: 4px;
				padding: 15px 12px;
				box-sizing: border-box;
				.mt{
					cursor: pointer;
					.delete-icon{
						width:14px;
						height:14px;
						margin-right:8px;
					}
					.delete-text{
						font-size:14px;
					}
				}
				.fileLibrary-box{
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin-bottom: 18px;
				}
				.fileLibrary_item_head {
					display: flex;
					align-items: center;
					// margin-bottom: 18px;

					.fileLibrary_item_circle {
						width: 7px;
						height: 7px;
						background: #000000;
						border-radius: 50%;
					}

					.fileLibrary_item_title {
						font-size: 16px;
						font-family: Source Han Sans CN;
						font-weight: bold;
						line-height: 24px;
						color: #333333;
						margin: 0 24px 0 8px;
					}

					.fileLibrary_item_time {
						font-size: 14px;
						font-family: Source Han Sans CN;
						font-weight: 500;
						line-height: 24px;
						color: #999999;
						display: inline-block;
						margin-right:23px;
					}
				}

				.fileLibrary_item_content {
					display: flex;
					margin-left: 8px;

					.fileLibrary_item_file {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						margin-right: 40px;

						.fileLibrary_item_cover {
							width: 200px;
							height: 120px;
							border-radius: 4px;
							margin-bottom: 15px;
						}
						.table-image{
							width: 200px;
							height: 120px;
							border-radius: 4px;
							margin-bottom: 15px;
						}
						.img-cover{
							cursor: pointer;
						}
						.fileLibrary_item_btn {
							width: 80px;
							line-height: 24px;
							background: #EEF6F4;
							border: 1px solid $theme_color;
							border-radius: 12px;
							text-align: center;
							font-size: 14px;
							font-family: Source Han Sans CN;
							font-weight: 400;
							color: $theme_color;
							cursor: pointer;
						}
					}

					.fileLibrary_item_desc {
						display: flex;
						align-items: flex-start;

						.fileLibrary_item_label {
							font-size: 14px;
							font-family: Source Han Sans CN;
							font-weight: 500;
							line-height: 20px;
							color: #333333;
							margin-right: 20px;
						}

						.fileLibrary_item_textarea {
							width: 412px;
							height: 159px;
							background: #FFFFFF;
							border: 1px solid #EEEEEE;
							border-radius: 4px;
							resize: none;
							padding: 10px 10px;
							font-size: 14px;
							font-family: Source Han Sans CN;
							font-weight: 400;
							color: #333333;
							box-sizing: border-box;
						}
					}
				}
			}
		}
		
		.fileLibrary_empty {
			flex: 1;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			img {
				width: 300px;
				height: 250px;
			}
			
			span {
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				line-height: 20px;
				color: #999999;
			}
		}

		.fileLibrary_dialog {
			.handle_form {
				display: flex;
				flex-direction: column;

				.handle_form_row {
					margin-top: 25px;
					display: flex;
					// padding: 0 23px;

					.handle_form_item {
						flex: 1;
						display: flex;

						.handle_form_ipt {
							width: 407px;
							border: 1px solid #EEEEEE;
							outline: none;
							font-size: 14px;
							font-family: Source Han Sans CN;
							font-weight: 400;
							line-height: 46px;
							color: #000000;
							padding: 0 10px;
							box-sizing: border-box;
						}

						.handle_form_textarea {
							width: 407px;
							height: 120px;
							border: 1px solid #EEEEEE;
							border-radius: 4px;
							resize: none;
							padding: 10px 10px;
							font-size: 14px;
							font-family: Source Han Sans CN;
							font-weight: 400;
							color: #333333;
							box-sizing: border-box;
						}

						.handle_form_upload {
							display: flex;
							flex-direction: column;

							.form_upload_box {
								display: flex;
								align-items: center;
								justify-content: center;
								width: 128px;
								line-height: 34px;
								background: #FFFFFF;
								border: 1px solid #EEEEEE;
								border-radius: 4px;
								font-size: 14px;
								font-family: Source Han Sans CN;
								font-weight: 400;
								color: #000000;
								cursor: pointer;

								.form_upload_icon {
									width: 14px;
									height: 14px;
									margin-right: 10px;
								}
							}

							.form_upload_tip {
								font-size: 12px;
								font-family: Source Han Sans CN;
								font-weight: 400;
								line-height: 17px;
								color: #999999;
								margin-top: 5px;
							}
						}
					}
				}

				.handle_form_btn {
					display: flex;
					align-items: center;
					margin: 40px 0;

					.handle_form_cancel {
						margin-left: auto;
						width: 76px;
						line-height: 34px;
						background: #FFFFFF;
						border: 1px solid $theme_color;
						border-radius: 4px;
						font-size: 14px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: $theme_color;
						text-align: center;
						cursor: pointer;
						margin-right: 16px;
					}

					.handle_form_confirm {
						width: 76px;
						line-height: 34px;
						background: $theme_color;
						border-radius: 4px;
						font-size: 14px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #FFF;
						text-align: center;
						cursor: pointer;
					}
				}
			}
		}
	}
</style>
